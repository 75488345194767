/*
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */
import React from 'react'

export type BootstrapIconName = keyof typeof BootstrapLazyIcons

export const isBootstrapIconName = (name: string): name is BootstrapIconName => {
  return name in BootstrapLazyIcons
}

export const BootstrapLazyIcons = {
  '0-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/0-circle-fill')),
  '0-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/0-circle')),
  '0-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/0-square-fill')),
  '0-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/0-square')),
  '1-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/1-circle-fill')),
  '1-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/1-circle')),
  '1-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/1-square-fill')),
  '1-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/1-square')),
  '123': React.lazy(() => import('react-bootstrap-icons/dist/icons/123')),
  '2-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/2-circle-fill')),
  '2-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/2-circle')),
  '2-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/2-square-fill')),
  '2-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/2-square')),
  '3-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/3-circle-fill')),
  '3-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/3-circle')),
  '3-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/3-square-fill')),
  '3-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/3-square')),
  '4-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/4-circle-fill')),
  '4-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/4-circle')),
  '4-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/4-square-fill')),
  '4-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/4-square')),
  '5-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/5-circle-fill')),
  '5-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/5-circle')),
  '5-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/5-square-fill')),
  '5-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/5-square')),
  '6-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/6-circle-fill')),
  '6-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/6-circle')),
  '6-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/6-square-fill')),
  '6-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/6-square')),
  '7-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/7-circle-fill')),
  '7-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/7-circle')),
  '7-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/7-square-fill')),
  '7-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/7-square')),
  '8-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/8-circle-fill')),
  '8-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/8-circle')),
  '8-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/8-square-fill')),
  '8-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/8-square')),
  '9-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/9-circle-fill')),
  '9-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/9-circle')),
  '9-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/9-square-fill')),
  '9-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/9-square')),
  activity: React.lazy(() => import('react-bootstrap-icons/dist/icons/activity')),
  'airplane-engines-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/airplane-engines-fill')),
  'airplane-engines': React.lazy(() => import('react-bootstrap-icons/dist/icons/airplane-engines')),
  'airplane-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/airplane-fill')),
  airplane: React.lazy(() => import('react-bootstrap-icons/dist/icons/airplane')),
  'alarm-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/alarm-fill')),
  alarm: React.lazy(() => import('react-bootstrap-icons/dist/icons/alarm')),
  alexa: React.lazy(() => import('react-bootstrap-icons/dist/icons/alexa')),
  'align-bottom': React.lazy(() => import('react-bootstrap-icons/dist/icons/align-bottom')),
  'align-center': React.lazy(() => import('react-bootstrap-icons/dist/icons/align-center')),
  'align-end': React.lazy(() => import('react-bootstrap-icons/dist/icons/align-end')),
  'align-middle': React.lazy(() => import('react-bootstrap-icons/dist/icons/align-middle')),
  'align-start': React.lazy(() => import('react-bootstrap-icons/dist/icons/align-start')),
  'align-top': React.lazy(() => import('react-bootstrap-icons/dist/icons/align-top')),
  alipay: React.lazy(() => import('react-bootstrap-icons/dist/icons/alipay')),
  alt: React.lazy(() => import('react-bootstrap-icons/dist/icons/alt')),
  amd: React.lazy(() => import('react-bootstrap-icons/dist/icons/amd')),
  android: React.lazy(() => import('react-bootstrap-icons/dist/icons/android')),
  android2: React.lazy(() => import('react-bootstrap-icons/dist/icons/android2')),
  'app-indicator': React.lazy(() => import('react-bootstrap-icons/dist/icons/app-indicator')),
  app: React.lazy(() => import('react-bootstrap-icons/dist/icons/app')),
  apple: React.lazy(() => import('react-bootstrap-icons/dist/icons/apple')),
  'archive-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/archive-fill')),
  archive: React.lazy(() => import('react-bootstrap-icons/dist/icons/archive')),
  'arrow-90deg-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-90deg-down')),
  'arrow-90deg-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-90deg-left')),
  'arrow-90deg-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-90deg-right')),
  'arrow-90deg-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-90deg-up')),
  'arrow-bar-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-bar-down')),
  'arrow-bar-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-bar-left')),
  'arrow-bar-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-bar-right')),
  'arrow-bar-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-bar-up')),
  'arrow-clockwise': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-clockwise')),
  'arrow-counterclockwise': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-counterclockwise')),
  'arrow-down-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-circle-fill')),
  'arrow-down-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-circle')),
  'arrow-down-left-circle-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/arrow-down-left-circle-fill')
  ),
  'arrow-down-left-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-left-circle')),
  'arrow-down-left-square-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/arrow-down-left-square-fill')
  ),
  'arrow-down-left-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-left-square')),
  'arrow-down-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-left')),
  'arrow-down-right-circle-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/arrow-down-right-circle-fill')
  ),
  'arrow-down-right-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-right-circle')),
  'arrow-down-right-square-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/arrow-down-right-square-fill')
  ),
  'arrow-down-right-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-right-square')),
  'arrow-down-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-right')),
  'arrow-down-short': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-short')),
  'arrow-down-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-square-fill')),
  'arrow-down-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-square')),
  'arrow-down-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down-up')),
  'arrow-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-down')),
  'arrow-left-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left-circle-fill')),
  'arrow-left-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left-circle')),
  'arrow-left-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left-right')),
  'arrow-left-short': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left-short')),
  'arrow-left-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left-square-fill')),
  'arrow-left-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left-square')),
  'arrow-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-left')),
  'arrow-repeat': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-repeat')),
  'arrow-return-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-return-left')),
  'arrow-return-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-return-right')),
  'arrow-right-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-right-circle-fill')),
  'arrow-right-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-right-circle')),
  'arrow-right-short': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-right-short')),
  'arrow-right-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-right-square-fill')),
  'arrow-right-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-right-square')),
  'arrow-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-right')),
  'arrow-through-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-through-heart-fill')),
  'arrow-through-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-through-heart')),
  'arrow-up-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-circle-fill')),
  'arrow-up-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-circle')),
  'arrow-up-left-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-left-circle-fill')),
  'arrow-up-left-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-left-circle')),
  'arrow-up-left-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-left-square-fill')),
  'arrow-up-left-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-left-square')),
  'arrow-up-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-left')),
  'arrow-up-right-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-right-circle-fill')),
  'arrow-up-right-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-right-circle')),
  'arrow-up-right-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-right-square-fill')),
  'arrow-up-right-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-right-square')),
  'arrow-up-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-right')),
  'arrow-up-short': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-short')),
  'arrow-up-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-square-fill')),
  'arrow-up-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up-square')),
  'arrow-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrow-up')),
  'arrows-angle-contract': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrows-angle-contract')),
  'arrows-angle-expand': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrows-angle-expand')),
  'arrows-collapse': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrows-collapse')),
  'arrows-expand': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrows-expand')),
  'arrows-fullscreen': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrows-fullscreen')),
  'arrows-move': React.lazy(() => import('react-bootstrap-icons/dist/icons/arrows-move')),
  'aspect-ratio-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/aspect-ratio-fill')),
  'aspect-ratio': React.lazy(() => import('react-bootstrap-icons/dist/icons/aspect-ratio')),
  asterisk: React.lazy(() => import('react-bootstrap-icons/dist/icons/asterisk')),
  at: React.lazy(() => import('react-bootstrap-icons/dist/icons/at')),
  'award-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/award-fill')),
  award: React.lazy(() => import('react-bootstrap-icons/dist/icons/award')),
  back: React.lazy(() => import('react-bootstrap-icons/dist/icons/back')),
  'backspace-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/backspace-fill')),
  'backspace-reverse-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/backspace-reverse-fill')),
  'backspace-reverse': React.lazy(() => import('react-bootstrap-icons/dist/icons/backspace-reverse')),
  backspace: React.lazy(() => import('react-bootstrap-icons/dist/icons/backspace')),
  'badge-3d-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-3d-fill')),
  'badge-3d': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-3d')),
  'badge-4k-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-4k-fill')),
  'badge-4k': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-4k')),
  'badge-8k-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-8k-fill')),
  'badge-8k': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-8k')),
  'badge-ad-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-ad-fill')),
  'badge-ad': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-ad')),
  'badge-ar-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-ar-fill')),
  'badge-ar': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-ar')),
  'badge-cc-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-cc-fill')),
  'badge-cc': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-cc')),
  'badge-hd-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-hd-fill')),
  'badge-hd': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-hd')),
  'badge-sd-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-sd-fill')),
  'badge-sd': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-sd')),
  'badge-tm-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-tm-fill')),
  'badge-tm': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-tm')),
  'badge-vo-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-vo-fill')),
  'badge-vo': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-vo')),
  'badge-vr-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-vr-fill')),
  'badge-vr': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-vr')),
  'badge-wc-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-wc-fill')),
  'badge-wc': React.lazy(() => import('react-bootstrap-icons/dist/icons/badge-wc')),
  'bag-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-check-fill')),
  'bag-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-check')),
  'bag-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-dash-fill')),
  'bag-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-dash')),
  'bag-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-fill')),
  'bag-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-heart-fill')),
  'bag-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-heart')),
  'bag-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-plus-fill')),
  'bag-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-plus')),
  'bag-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-x-fill')),
  'bag-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/bag-x')),
  bag: React.lazy(() => import('react-bootstrap-icons/dist/icons/bag')),
  'balloon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/balloon-fill')),
  'balloon-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/balloon-heart-fill')),
  'balloon-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/balloon-heart')),
  balloon: React.lazy(() => import('react-bootstrap-icons/dist/icons/balloon')),
  'bandaid-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bandaid-fill')),
  bandaid: React.lazy(() => import('react-bootstrap-icons/dist/icons/bandaid')),
  bank: React.lazy(() => import('react-bootstrap-icons/dist/icons/bank')),
  bank2: React.lazy(() => import('react-bootstrap-icons/dist/icons/bank2')),
  'bar-chart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bar-chart-fill')),
  'bar-chart-line-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bar-chart-line-fill')),
  'bar-chart-line': React.lazy(() => import('react-bootstrap-icons/dist/icons/bar-chart-line')),
  'bar-chart-steps': React.lazy(() => import('react-bootstrap-icons/dist/icons/bar-chart-steps')),
  'bar-chart': React.lazy(() => import('react-bootstrap-icons/dist/icons/bar-chart')),
  'basket-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/basket-fill')),
  basket: React.lazy(() => import('react-bootstrap-icons/dist/icons/basket')),
  'basket2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/basket2-fill')),
  basket2: React.lazy(() => import('react-bootstrap-icons/dist/icons/basket2')),
  'basket3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/basket3-fill')),
  basket3: React.lazy(() => import('react-bootstrap-icons/dist/icons/basket3')),
  'battery-charging': React.lazy(() => import('react-bootstrap-icons/dist/icons/battery-charging')),
  'battery-full': React.lazy(() => import('react-bootstrap-icons/dist/icons/battery-full')),
  'battery-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/battery-half')),
  battery: React.lazy(() => import('react-bootstrap-icons/dist/icons/battery')),
  behance: React.lazy(() => import('react-bootstrap-icons/dist/icons/behance')),
  'bell-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bell-fill')),
  'bell-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bell-slash-fill')),
  'bell-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/bell-slash')),
  bell: React.lazy(() => import('react-bootstrap-icons/dist/icons/bell')),
  bezier: React.lazy(() => import('react-bootstrap-icons/dist/icons/bezier')),
  bezier2: React.lazy(() => import('react-bootstrap-icons/dist/icons/bezier2')),
  bicycle: React.lazy(() => import('react-bootstrap-icons/dist/icons/bicycle')),
  'binoculars-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/binoculars-fill')),
  binoculars: React.lazy(() => import('react-bootstrap-icons/dist/icons/binoculars')),
  'blockquote-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/blockquote-left')),
  'blockquote-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/blockquote-right')),
  bluetooth: React.lazy(() => import('react-bootstrap-icons/dist/icons/bluetooth')),
  'body-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/body-text')),
  'book-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/book-fill')),
  'book-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/book-half')),
  book: React.lazy(() => import('react-bootstrap-icons/dist/icons/book')),
  'bookmark-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-check-fill')),
  'bookmark-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-check')),
  'bookmark-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-dash-fill')),
  'bookmark-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-dash')),
  'bookmark-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-fill')),
  'bookmark-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-heart-fill')),
  'bookmark-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-heart')),
  'bookmark-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-plus-fill')),
  'bookmark-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-plus')),
  'bookmark-star-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-star-fill')),
  'bookmark-star': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-star')),
  'bookmark-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-x-fill')),
  'bookmark-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark-x')),
  bookmark: React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmark')),
  'bookmarks-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmarks-fill')),
  bookmarks: React.lazy(() => import('react-bootstrap-icons/dist/icons/bookmarks')),
  bookshelf: React.lazy(() => import('react-bootstrap-icons/dist/icons/bookshelf')),
  'boombox-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/boombox-fill')),
  boombox: React.lazy(() => import('react-bootstrap-icons/dist/icons/boombox')),
  'bootstrap-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bootstrap-fill')),
  'bootstrap-reboot': React.lazy(() => import('react-bootstrap-icons/dist/icons/bootstrap-reboot')),
  bootstrap: React.lazy(() => import('react-bootstrap-icons/dist/icons/bootstrap')),
  'border-all': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-all')),
  'border-bottom': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-bottom')),
  'border-center': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-center')),
  'border-inner': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-inner')),
  'border-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-left')),
  'border-middle': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-middle')),
  'border-outer': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-outer')),
  'border-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-right')),
  'border-style': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-style')),
  'border-top': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-top')),
  'border-width': React.lazy(() => import('react-bootstrap-icons/dist/icons/border-width')),
  border: React.lazy(() => import('react-bootstrap-icons/dist/icons/border')),
  'bounding-box-circles': React.lazy(() => import('react-bootstrap-icons/dist/icons/bounding-box-circles')),
  'bounding-box': React.lazy(() => import('react-bootstrap-icons/dist/icons/bounding-box')),
  'box-arrow-down-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-down-left')),
  'box-arrow-down-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-down-right')),
  'box-arrow-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-down')),
  'box-arrow-in-down-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-down-left')),
  'box-arrow-in-down-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-down-right')),
  'box-arrow-in-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-down')),
  'box-arrow-in-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-left')),
  'box-arrow-in-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-right')),
  'box-arrow-in-up-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-up-left')),
  'box-arrow-in-up-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-up-right')),
  'box-arrow-in-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-in-up')),
  'box-arrow-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-left')),
  'box-arrow-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-right')),
  'box-arrow-up-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-up-left')),
  'box-arrow-up-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-up-right')),
  'box-arrow-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-arrow-up')),
  'box-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-fill')),
  'box-seam-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-seam-fill')),
  'box-seam': React.lazy(() => import('react-bootstrap-icons/dist/icons/box-seam')),
  box: React.lazy(() => import('react-bootstrap-icons/dist/icons/box')),
  'box2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/box2-fill')),
  'box2-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/box2-heart-fill')),
  'box2-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/box2-heart')),
  box2: React.lazy(() => import('react-bootstrap-icons/dist/icons/box2')),
  boxes: React.lazy(() => import('react-bootstrap-icons/dist/icons/boxes')),
  'braces-asterisk': React.lazy(() => import('react-bootstrap-icons/dist/icons/braces-asterisk')),
  braces: React.lazy(() => import('react-bootstrap-icons/dist/icons/braces')),
  bricks: React.lazy(() => import('react-bootstrap-icons/dist/icons/bricks')),
  'briefcase-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/briefcase-fill')),
  briefcase: React.lazy(() => import('react-bootstrap-icons/dist/icons/briefcase')),
  'brightness-alt-high-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-alt-high-fill')),
  'brightness-alt-high': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-alt-high')),
  'brightness-alt-low-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-alt-low-fill')),
  'brightness-alt-low': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-alt-low')),
  'brightness-high-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-high-fill')),
  'brightness-high': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-high')),
  'brightness-low-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-low-fill')),
  'brightness-low': React.lazy(() => import('react-bootstrap-icons/dist/icons/brightness-low')),
  'broadcast-pin': React.lazy(() => import('react-bootstrap-icons/dist/icons/broadcast-pin')),
  broadcast: React.lazy(() => import('react-bootstrap-icons/dist/icons/broadcast')),
  'browser-chrome': React.lazy(() => import('react-bootstrap-icons/dist/icons/browser-chrome')),
  'browser-edge': React.lazy(() => import('react-bootstrap-icons/dist/icons/browser-edge')),
  'browser-firefox': React.lazy(() => import('react-bootstrap-icons/dist/icons/browser-firefox')),
  'browser-safari': React.lazy(() => import('react-bootstrap-icons/dist/icons/browser-safari')),
  'brush-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/brush-fill')),
  brush: React.lazy(() => import('react-bootstrap-icons/dist/icons/brush')),
  'bucket-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bucket-fill')),
  bucket: React.lazy(() => import('react-bootstrap-icons/dist/icons/bucket')),
  'bug-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bug-fill')),
  bug: React.lazy(() => import('react-bootstrap-icons/dist/icons/bug')),
  'building-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-add')),
  'building-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-check')),
  'building-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-dash')),
  'building-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-down')),
  'building-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-exclamation')),
  'building-fill-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-add')),
  'building-fill-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-check')),
  'building-fill-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-dash')),
  'building-fill-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-down')),
  'building-fill-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-exclamation')),
  'building-fill-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-gear')),
  'building-fill-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-lock')),
  'building-fill-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-slash')),
  'building-fill-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-up')),
  'building-fill-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill-x')),
  'building-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-fill')),
  'building-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-gear')),
  'building-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-lock')),
  'building-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-slash')),
  'building-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-up')),
  'building-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/building-x')),
  building: React.lazy(() => import('react-bootstrap-icons/dist/icons/building')),
  'buildings-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/buildings-fill')),
  buildings: React.lazy(() => import('react-bootstrap-icons/dist/icons/buildings')),
  bullseye: React.lazy(() => import('react-bootstrap-icons/dist/icons/bullseye')),
  'bus-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/bus-front-fill')),
  'bus-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/bus-front')),
  'c-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/c-circle-fill')),
  'c-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/c-circle')),
  'c-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/c-square-fill')),
  'c-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/c-square')),
  'calculator-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calculator-fill')),
  calculator: React.lazy(() => import('react-bootstrap-icons/dist/icons/calculator')),
  'calendar-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-check-fill')),
  'calendar-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-check')),
  'calendar-date-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-date-fill')),
  'calendar-date': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-date')),
  'calendar-day-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-day-fill')),
  'calendar-day': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-day')),
  'calendar-event-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-event-fill')),
  'calendar-event': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-event')),
  'calendar-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-fill')),
  'calendar-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-heart-fill')),
  'calendar-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-heart')),
  'calendar-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-minus-fill')),
  'calendar-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-minus')),
  'calendar-month-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-month-fill')),
  'calendar-month': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-month')),
  'calendar-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-plus-fill')),
  'calendar-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-plus')),
  'calendar-range-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-range-fill')),
  'calendar-range': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-range')),
  'calendar-week-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-week-fill')),
  'calendar-week': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-week')),
  'calendar-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-x-fill')),
  'calendar-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar-x')),
  calendar: React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar')),
  'calendar2-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-check-fill')),
  'calendar2-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-check')),
  'calendar2-date-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-date-fill')),
  'calendar2-date': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-date')),
  'calendar2-day-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-day-fill')),
  'calendar2-day': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-day')),
  'calendar2-event-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-event-fill')),
  'calendar2-event': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-event')),
  'calendar2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-fill')),
  'calendar2-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-heart-fill')),
  'calendar2-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-heart')),
  'calendar2-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-minus-fill')),
  'calendar2-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-minus')),
  'calendar2-month-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-month-fill')),
  'calendar2-month': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-month')),
  'calendar2-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-plus-fill')),
  'calendar2-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-plus')),
  'calendar2-range-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-range-fill')),
  'calendar2-range': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-range')),
  'calendar2-week-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-week-fill')),
  'calendar2-week': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-week')),
  'calendar2-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-x-fill')),
  'calendar2-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2-x')),
  calendar2: React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar2')),
  'calendar3-event-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-event-fill')),
  'calendar3-event': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-event')),
  'calendar3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-fill')),
  'calendar3-range-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-range-fill')),
  'calendar3-range': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-range')),
  'calendar3-week-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-week-fill')),
  'calendar3-week': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3-week')),
  calendar3: React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar3')),
  'calendar4-event': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar4-event')),
  'calendar4-range': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar4-range')),
  'calendar4-week': React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar4-week')),
  calendar4: React.lazy(() => import('react-bootstrap-icons/dist/icons/calendar4')),
  'camera-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-fill')),
  'camera-reels-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-reels-fill')),
  'camera-reels': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-reels')),
  'camera-video-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-video-fill')),
  'camera-video-off-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-video-off-fill')),
  'camera-video-off': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-video-off')),
  'camera-video': React.lazy(() => import('react-bootstrap-icons/dist/icons/camera-video')),
  camera: React.lazy(() => import('react-bootstrap-icons/dist/icons/camera')),
  camera2: React.lazy(() => import('react-bootstrap-icons/dist/icons/camera2')),
  'capslock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/capslock-fill')),
  capslock: React.lazy(() => import('react-bootstrap-icons/dist/icons/capslock')),
  'capsule-pill': React.lazy(() => import('react-bootstrap-icons/dist/icons/capsule-pill')),
  capsule: React.lazy(() => import('react-bootstrap-icons/dist/icons/capsule')),
  'car-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/car-front-fill')),
  'car-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/car-front')),
  'card-checklist': React.lazy(() => import('react-bootstrap-icons/dist/icons/card-checklist')),
  'card-heading': React.lazy(() => import('react-bootstrap-icons/dist/icons/card-heading')),
  'card-image': React.lazy(() => import('react-bootstrap-icons/dist/icons/card-image')),
  'card-list': React.lazy(() => import('react-bootstrap-icons/dist/icons/card-list')),
  'card-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/card-text')),
  'caret-down-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-down-fill')),
  'caret-down-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-down-square-fill')),
  'caret-down-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-down-square')),
  'caret-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-down')),
  'caret-left-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-left-fill')),
  'caret-left-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-left-square-fill')),
  'caret-left-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-left-square')),
  'caret-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-left')),
  'caret-right-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-right-fill')),
  'caret-right-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-right-square-fill')),
  'caret-right-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-right-square')),
  'caret-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-right')),
  'caret-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-up-fill')),
  'caret-up-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-up-square-fill')),
  'caret-up-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-up-square')),
  'caret-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/caret-up')),
  'cart-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-check-fill')),
  'cart-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-check')),
  'cart-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-dash-fill')),
  'cart-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-dash')),
  'cart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-fill')),
  'cart-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-plus-fill')),
  'cart-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-plus')),
  'cart-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-x-fill')),
  'cart-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/cart-x')),
  cart: React.lazy(() => import('react-bootstrap-icons/dist/icons/cart')),
  cart2: React.lazy(() => import('react-bootstrap-icons/dist/icons/cart2')),
  cart3: React.lazy(() => import('react-bootstrap-icons/dist/icons/cart3')),
  cart4: React.lazy(() => import('react-bootstrap-icons/dist/icons/cart4')),
  'cash-coin': React.lazy(() => import('react-bootstrap-icons/dist/icons/cash-coin')),
  'cash-stack': React.lazy(() => import('react-bootstrap-icons/dist/icons/cash-stack')),
  cash: React.lazy(() => import('react-bootstrap-icons/dist/icons/cash')),
  'cassette-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cassette-fill')),
  cassette: React.lazy(() => import('react-bootstrap-icons/dist/icons/cassette')),
  cast: React.lazy(() => import('react-bootstrap-icons/dist/icons/cast')),
  'cc-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cc-circle-fill')),
  'cc-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/cc-circle')),
  'cc-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cc-square-fill')),
  'cc-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/cc-square')),
  'chat-dots-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-dots-fill')),
  'chat-dots': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-dots')),
  'chat-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-fill')),
  'chat-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-heart-fill')),
  'chat-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-heart')),
  'chat-left-dots-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-dots-fill')),
  'chat-left-dots': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-dots')),
  'chat-left-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-fill')),
  'chat-left-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-heart-fill')),
  'chat-left-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-heart')),
  'chat-left-quote-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-quote-fill')),
  'chat-left-quote': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-quote')),
  'chat-left-text-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-text-fill')),
  'chat-left-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left-text')),
  'chat-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-left')),
  'chat-quote-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-quote-fill')),
  'chat-quote': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-quote')),
  'chat-right-dots-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-dots-fill')),
  'chat-right-dots': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-dots')),
  'chat-right-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-fill')),
  'chat-right-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-heart-fill')),
  'chat-right-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-heart')),
  'chat-right-quote-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-quote-fill')),
  'chat-right-quote': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-quote')),
  'chat-right-text-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-text-fill')),
  'chat-right-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right-text')),
  'chat-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-right')),
  'chat-square-dots-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-dots-fill')),
  'chat-square-dots': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-dots')),
  'chat-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-fill')),
  'chat-square-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-heart-fill')),
  'chat-square-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-heart')),
  'chat-square-quote-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-quote-fill')),
  'chat-square-quote': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-quote')),
  'chat-square-text-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-text-fill')),
  'chat-square-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square-text')),
  'chat-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-square')),
  'chat-text-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-text-fill')),
  'chat-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/chat-text')),
  chat: React.lazy(() => import('react-bootstrap-icons/dist/icons/chat')),
  'check-all': React.lazy(() => import('react-bootstrap-icons/dist/icons/check-all')),
  'check-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/check-circle-fill')),
  'check-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/check-circle')),
  'check-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/check-lg')),
  'check-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/check-square-fill')),
  'check-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/check-square')),
  check: React.lazy(() => import('react-bootstrap-icons/dist/icons/check')),
  'check2-all': React.lazy(() => import('react-bootstrap-icons/dist/icons/check2-all')),
  'check2-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/check2-circle')),
  'check2-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/check2-square')),
  check2: React.lazy(() => import('react-bootstrap-icons/dist/icons/check2')),
  'chevron-bar-contract': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-bar-contract')),
  'chevron-bar-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-bar-down')),
  'chevron-bar-expand': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-bar-expand')),
  'chevron-bar-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-bar-left')),
  'chevron-bar-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-bar-right')),
  'chevron-bar-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-bar-up')),
  'chevron-compact-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-compact-down')),
  'chevron-compact-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-compact-left')),
  'chevron-compact-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-compact-right')),
  'chevron-compact-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-compact-up')),
  'chevron-contract': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-contract')),
  'chevron-double-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-double-down')),
  'chevron-double-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-double-left')),
  'chevron-double-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-double-right')),
  'chevron-double-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-double-up')),
  'chevron-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-down')),
  'chevron-expand': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-expand')),
  'chevron-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-left')),
  'chevron-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-right')),
  'chevron-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/chevron-up')),
  'circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/circle-fill')),
  'circle-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/circle-half')),
  'circle-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/circle-square')),
  circle: React.lazy(() => import('react-bootstrap-icons/dist/icons/circle')),
  'clipboard-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-check-fill')),
  'clipboard-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-check')),
  'clipboard-data-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-data-fill')),
  'clipboard-data': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-data')),
  'clipboard-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-fill')),
  'clipboard-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-heart-fill')),
  'clipboard-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-heart')),
  'clipboard-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-minus-fill')),
  'clipboard-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-minus')),
  'clipboard-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-plus-fill')),
  'clipboard-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-plus')),
  'clipboard-pulse': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-pulse')),
  'clipboard-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-x-fill')),
  'clipboard-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard-x')),
  clipboard: React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard')),
  'clipboard2-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-check-fill')),
  'clipboard2-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-check')),
  'clipboard2-data-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-data-fill')),
  'clipboard2-data': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-data')),
  'clipboard2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-fill')),
  'clipboard2-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-heart-fill')),
  'clipboard2-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-heart')),
  'clipboard2-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-minus-fill')),
  'clipboard2-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-minus')),
  'clipboard2-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-plus-fill')),
  'clipboard2-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-plus')),
  'clipboard2-pulse-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-pulse-fill')),
  'clipboard2-pulse': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-pulse')),
  'clipboard2-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-x-fill')),
  'clipboard2-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2-x')),
  clipboard2: React.lazy(() => import('react-bootstrap-icons/dist/icons/clipboard2')),
  'clock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clock-fill')),
  'clock-history': React.lazy(() => import('react-bootstrap-icons/dist/icons/clock-history')),
  clock: React.lazy(() => import('react-bootstrap-icons/dist/icons/clock')),
  'cloud-arrow-down-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-arrow-down-fill')),
  'cloud-arrow-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-arrow-down')),
  'cloud-arrow-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-arrow-up-fill')),
  'cloud-arrow-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-arrow-up')),
  'cloud-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-check-fill')),
  'cloud-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-check')),
  'cloud-download-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-download-fill')),
  'cloud-download': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-download')),
  'cloud-drizzle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-drizzle-fill')),
  'cloud-drizzle': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-drizzle')),
  'cloud-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-fill')),
  'cloud-fog-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-fog-fill')),
  'cloud-fog': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-fog')),
  'cloud-fog2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-fog2-fill')),
  'cloud-fog2': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-fog2')),
  'cloud-hail-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-hail-fill')),
  'cloud-hail': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-hail')),
  'cloud-haze-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-haze-fill')),
  'cloud-haze': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-haze')),
  'cloud-haze2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-haze2-fill')),
  'cloud-haze2': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-haze2')),
  'cloud-lightning-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-lightning-fill')),
  'cloud-lightning-rain-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-lightning-rain-fill')),
  'cloud-lightning-rain': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-lightning-rain')),
  'cloud-lightning': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-lightning')),
  'cloud-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-minus-fill')),
  'cloud-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-minus')),
  'cloud-moon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-moon-fill')),
  'cloud-moon': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-moon')),
  'cloud-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-plus-fill')),
  'cloud-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-plus')),
  'cloud-rain-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-rain-fill')),
  'cloud-rain-heavy-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-rain-heavy-fill')),
  'cloud-rain-heavy': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-rain-heavy')),
  'cloud-rain': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-rain')),
  'cloud-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-slash-fill')),
  'cloud-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-slash')),
  'cloud-sleet-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-sleet-fill')),
  'cloud-sleet': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-sleet')),
  'cloud-snow-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-snow-fill')),
  'cloud-snow': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-snow')),
  'cloud-sun-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-sun-fill')),
  'cloud-sun': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-sun')),
  'cloud-upload-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-upload-fill')),
  'cloud-upload': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud-upload')),
  cloud: React.lazy(() => import('react-bootstrap-icons/dist/icons/cloud')),
  'clouds-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/clouds-fill')),
  clouds: React.lazy(() => import('react-bootstrap-icons/dist/icons/clouds')),
  'cloudy-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cloudy-fill')),
  cloudy: React.lazy(() => import('react-bootstrap-icons/dist/icons/cloudy')),
  'code-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/code-slash')),
  'code-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/code-square')),
  code: React.lazy(() => import('react-bootstrap-icons/dist/icons/code')),
  coin: React.lazy(() => import('react-bootstrap-icons/dist/icons/coin')),
  'collection-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/collection-fill')),
  'collection-play-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/collection-play-fill')),
  'collection-play': React.lazy(() => import('react-bootstrap-icons/dist/icons/collection-play')),
  collection: React.lazy(() => import('react-bootstrap-icons/dist/icons/collection')),
  'columns-gap': React.lazy(() => import('react-bootstrap-icons/dist/icons/columns-gap')),
  columns: React.lazy(() => import('react-bootstrap-icons/dist/icons/columns')),
  command: React.lazy(() => import('react-bootstrap-icons/dist/icons/command')),
  'compass-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/compass-fill')),
  compass: React.lazy(() => import('react-bootstrap-icons/dist/icons/compass')),
  'cone-striped': React.lazy(() => import('react-bootstrap-icons/dist/icons/cone-striped')),
  cone: React.lazy(() => import('react-bootstrap-icons/dist/icons/cone')),
  controller: React.lazy(() => import('react-bootstrap-icons/dist/icons/controller')),
  'cpu-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cpu-fill')),
  cpu: React.lazy(() => import('react-bootstrap-icons/dist/icons/cpu')),
  'credit-card-2-back-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/credit-card-2-back-fill')),
  'credit-card-2-back': React.lazy(() => import('react-bootstrap-icons/dist/icons/credit-card-2-back')),
  'credit-card-2-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/credit-card-2-front-fill')),
  'credit-card-2-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/credit-card-2-front')),
  'credit-card-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/credit-card-fill')),
  'credit-card': React.lazy(() => import('react-bootstrap-icons/dist/icons/credit-card')),
  crop: React.lazy(() => import('react-bootstrap-icons/dist/icons/crop')),
  'cup-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cup-fill')),
  'cup-hot-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cup-hot-fill')),
  'cup-hot': React.lazy(() => import('react-bootstrap-icons/dist/icons/cup-hot')),
  'cup-straw': React.lazy(() => import('react-bootstrap-icons/dist/icons/cup-straw')),
  cup: React.lazy(() => import('react-bootstrap-icons/dist/icons/cup')),
  'currency-bitcoin': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-bitcoin')),
  'currency-dollar': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-dollar')),
  'currency-euro': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-euro')),
  'currency-exchange': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-exchange')),
  'currency-pound': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-pound')),
  'currency-rupee': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-rupee')),
  'currency-yen': React.lazy(() => import('react-bootstrap-icons/dist/icons/currency-yen')),
  'cursor-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/cursor-fill')),
  'cursor-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/cursor-text')),
  cursor: React.lazy(() => import('react-bootstrap-icons/dist/icons/cursor')),
  'dash-circle-dotted': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-circle-dotted')),
  'dash-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-circle-fill')),
  'dash-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-circle')),
  'dash-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-lg')),
  'dash-square-dotted': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-square-dotted')),
  'dash-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-square-fill')),
  'dash-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/dash-square')),
  dash: React.lazy(() => import('react-bootstrap-icons/dist/icons/dash')),
  'database-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-add')),
  'database-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-check')),
  'database-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-dash')),
  'database-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-down')),
  'database-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-exclamation')),
  'database-fill-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-add')),
  'database-fill-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-check')),
  'database-fill-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-dash')),
  'database-fill-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-down')),
  'database-fill-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-exclamation')),
  'database-fill-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-gear')),
  'database-fill-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-lock')),
  'database-fill-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-slash')),
  'database-fill-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-up')),
  'database-fill-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill-x')),
  'database-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-fill')),
  'database-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-gear')),
  'database-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-lock')),
  'database-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-slash')),
  'database-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-up')),
  'database-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/database-x')),
  database: React.lazy(() => import('react-bootstrap-icons/dist/icons/database')),
  'device-hdd-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/device-hdd-fill')),
  'device-hdd': React.lazy(() => import('react-bootstrap-icons/dist/icons/device-hdd')),
  'device-ssd-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/device-ssd-fill')),
  'device-ssd': React.lazy(() => import('react-bootstrap-icons/dist/icons/device-ssd')),
  'diagram-2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/diagram-2-fill')),
  'diagram-2': React.lazy(() => import('react-bootstrap-icons/dist/icons/diagram-2')),
  'diagram-3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/diagram-3-fill')),
  'diagram-3': React.lazy(() => import('react-bootstrap-icons/dist/icons/diagram-3')),
  'diamond-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/diamond-fill')),
  'diamond-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/diamond-half')),
  diamond: React.lazy(() => import('react-bootstrap-icons/dist/icons/diamond')),
  'dice-1-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-1-fill')),
  'dice-1': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-1')),
  'dice-2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-2-fill')),
  'dice-2': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-2')),
  'dice-3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-3-fill')),
  'dice-3': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-3')),
  'dice-4-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-4-fill')),
  'dice-4': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-4')),
  'dice-5-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-5-fill')),
  'dice-5': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-5')),
  'dice-6-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-6-fill')),
  'dice-6': React.lazy(() => import('react-bootstrap-icons/dist/icons/dice-6')),
  'disc-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/disc-fill')),
  disc: React.lazy(() => import('react-bootstrap-icons/dist/icons/disc')),
  discord: React.lazy(() => import('react-bootstrap-icons/dist/icons/discord')),
  'display-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/display-fill')),
  display: React.lazy(() => import('react-bootstrap-icons/dist/icons/display')),
  'displayport-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/displayport-fill')),
  displayport: React.lazy(() => import('react-bootstrap-icons/dist/icons/displayport')),
  'distribute-horizontal': React.lazy(() => import('react-bootstrap-icons/dist/icons/distribute-horizontal')),
  'distribute-vertical': React.lazy(() => import('react-bootstrap-icons/dist/icons/distribute-vertical')),
  'door-closed-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/door-closed-fill')),
  'door-closed': React.lazy(() => import('react-bootstrap-icons/dist/icons/door-closed')),
  'door-open-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/door-open-fill')),
  'door-open': React.lazy(() => import('react-bootstrap-icons/dist/icons/door-open')),
  dot: React.lazy(() => import('react-bootstrap-icons/dist/icons/dot')),
  download: React.lazy(() => import('react-bootstrap-icons/dist/icons/download')),
  'dpad-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/dpad-fill')),
  dpad: React.lazy(() => import('react-bootstrap-icons/dist/icons/dpad')),
  dribbble: React.lazy(() => import('react-bootstrap-icons/dist/icons/dribbble')),
  dropbox: React.lazy(() => import('react-bootstrap-icons/dist/icons/dropbox')),
  'droplet-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/droplet-fill')),
  'droplet-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/droplet-half')),
  droplet: React.lazy(() => import('react-bootstrap-icons/dist/icons/droplet')),
  'ear-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/ear-fill')),
  ear: React.lazy(() => import('react-bootstrap-icons/dist/icons/ear')),
  earbuds: React.lazy(() => import('react-bootstrap-icons/dist/icons/earbuds')),
  'easel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/easel-fill')),
  easel: React.lazy(() => import('react-bootstrap-icons/dist/icons/easel')),
  'easel2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/easel2-fill')),
  easel2: React.lazy(() => import('react-bootstrap-icons/dist/icons/easel2')),
  'easel3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/easel3-fill')),
  easel3: React.lazy(() => import('react-bootstrap-icons/dist/icons/easel3')),
  'egg-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/egg-fill')),
  'egg-fried': React.lazy(() => import('react-bootstrap-icons/dist/icons/egg-fried')),
  egg: React.lazy(() => import('react-bootstrap-icons/dist/icons/egg')),
  'eject-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/eject-fill')),
  eject: React.lazy(() => import('react-bootstrap-icons/dist/icons/eject')),
  'emoji-angry-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-angry-fill')),
  'emoji-angry': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-angry')),
  'emoji-dizzy-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-dizzy-fill')),
  'emoji-dizzy': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-dizzy')),
  'emoji-expressionless-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-expressionless-fill')),
  'emoji-expressionless': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-expressionless')),
  'emoji-frown-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-frown-fill')),
  'emoji-frown': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-frown')),
  'emoji-heart-eyes-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-heart-eyes-fill')),
  'emoji-heart-eyes': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-heart-eyes')),
  'emoji-kiss-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-kiss-fill')),
  'emoji-kiss': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-kiss')),
  'emoji-laughing-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-laughing-fill')),
  'emoji-laughing': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-laughing')),
  'emoji-neutral-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-neutral-fill')),
  'emoji-neutral': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-neutral')),
  'emoji-smile-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-smile-fill')),
  'emoji-smile-upside-down-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/emoji-smile-upside-down-fill')
  ),
  'emoji-smile-upside-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-smile-upside-down')),
  'emoji-smile': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-smile')),
  'emoji-sunglasses-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-sunglasses-fill')),
  'emoji-sunglasses': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-sunglasses')),
  'emoji-wink-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-wink-fill')),
  'emoji-wink': React.lazy(() => import('react-bootstrap-icons/dist/icons/emoji-wink')),
  'envelope-at-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-at-fill')),
  'envelope-at': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-at')),
  'envelope-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-check-fill')),
  'envelope-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-check')),
  'envelope-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-dash-fill')),
  'envelope-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-dash')),
  'envelope-exclamation-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-exclamation-fill')),
  'envelope-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-exclamation')),
  'envelope-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-fill')),
  'envelope-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-heart-fill')),
  'envelope-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-heart')),
  'envelope-open-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-open-fill')),
  'envelope-open-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-open-heart-fill')),
  'envelope-open-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-open-heart')),
  'envelope-open': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-open')),
  'envelope-paper-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-paper-fill')),
  'envelope-paper-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-paper-heart-fill')),
  'envelope-paper-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-paper-heart')),
  'envelope-paper': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-paper')),
  'envelope-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-plus-fill')),
  'envelope-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-plus')),
  'envelope-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-slash-fill')),
  'envelope-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-slash')),
  'envelope-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-x-fill')),
  'envelope-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope-x')),
  envelope: React.lazy(() => import('react-bootstrap-icons/dist/icons/envelope')),
  'eraser-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/eraser-fill')),
  eraser: React.lazy(() => import('react-bootstrap-icons/dist/icons/eraser')),
  escape: React.lazy(() => import('react-bootstrap-icons/dist/icons/escape')),
  ethernet: React.lazy(() => import('react-bootstrap-icons/dist/icons/ethernet')),
  'ev-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/ev-front-fill')),
  'ev-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/ev-front')),
  'ev-station-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/ev-station-fill')),
  'ev-station': React.lazy(() => import('react-bootstrap-icons/dist/icons/ev-station')),
  'exclamation-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-circle-fill')),
  'exclamation-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-circle')),
  'exclamation-diamond-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-diamond-fill')),
  'exclamation-diamond': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-diamond')),
  'exclamation-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-lg')),
  'exclamation-octagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-octagon-fill')),
  'exclamation-octagon': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-octagon')),
  'exclamation-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-square-fill')),
  'exclamation-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-square')),
  'exclamation-triangle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-triangle-fill')),
  'exclamation-triangle': React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation-triangle')),
  exclamation: React.lazy(() => import('react-bootstrap-icons/dist/icons/exclamation')),
  exclude: React.lazy(() => import('react-bootstrap-icons/dist/icons/exclude')),
  'explicit-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/explicit-fill')),
  explicit: React.lazy(() => import('react-bootstrap-icons/dist/icons/explicit')),
  'eye-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/eye-fill')),
  'eye-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/eye-slash-fill')),
  'eye-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/eye-slash')),
  eye: React.lazy(() => import('react-bootstrap-icons/dist/icons/eye')),
  eyedropper: React.lazy(() => import('react-bootstrap-icons/dist/icons/eyedropper')),
  eyeglasses: React.lazy(() => import('react-bootstrap-icons/dist/icons/eyeglasses')),
  facebook: React.lazy(() => import('react-bootstrap-icons/dist/icons/facebook')),
  fan: React.lazy(() => import('react-bootstrap-icons/dist/icons/fan')),
  'fast-forward-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/fast-forward-btn-fill')),
  'fast-forward-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/fast-forward-btn')),
  'fast-forward-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/fast-forward-circle-fill')),
  'fast-forward-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/fast-forward-circle')),
  'fast-forward-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/fast-forward-fill')),
  'fast-forward': React.lazy(() => import('react-bootstrap-icons/dist/icons/fast-forward')),
  'file-arrow-down-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-arrow-down-fill')),
  'file-arrow-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-arrow-down')),
  'file-arrow-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-arrow-up-fill')),
  'file-arrow-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-arrow-up')),
  'file-bar-graph-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-bar-graph-fill')),
  'file-bar-graph': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-bar-graph')),
  'file-binary-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-binary-fill')),
  'file-binary': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-binary')),
  'file-break-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-break-fill')),
  'file-break': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-break')),
  'file-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-check-fill')),
  'file-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-check')),
  'file-code-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-code-fill')),
  'file-code': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-code')),
  'file-diff-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-diff-fill')),
  'file-diff': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-diff')),
  'file-earmark-arrow-down-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/file-earmark-arrow-down-fill')
  ),
  'file-earmark-arrow-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-arrow-down')),
  'file-earmark-arrow-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-arrow-up-fill')),
  'file-earmark-arrow-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-arrow-up')),
  'file-earmark-bar-graph-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/file-earmark-bar-graph-fill')
  ),
  'file-earmark-bar-graph': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-bar-graph')),
  'file-earmark-binary-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-binary-fill')),
  'file-earmark-binary': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-binary')),
  'file-earmark-break-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-break-fill')),
  'file-earmark-break': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-break')),
  'file-earmark-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-check-fill')),
  'file-earmark-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-check')),
  'file-earmark-code-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-code-fill')),
  'file-earmark-code': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-code')),
  'file-earmark-diff-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-diff-fill')),
  'file-earmark-diff': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-diff')),
  'file-earmark-easel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-easel-fill')),
  'file-earmark-easel': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-easel')),
  'file-earmark-excel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-excel-fill')),
  'file-earmark-excel': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-excel')),
  'file-earmark-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-fill')),
  'file-earmark-font-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-font-fill')),
  'file-earmark-font': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-font')),
  'file-earmark-image-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-image-fill')),
  'file-earmark-image': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-image')),
  'file-earmark-lock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-lock-fill')),
  'file-earmark-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-lock')),
  'file-earmark-lock2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-lock2-fill')),
  'file-earmark-lock2': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-lock2')),
  'file-earmark-medical-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-medical-fill')),
  'file-earmark-medical': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-medical')),
  'file-earmark-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-minus-fill')),
  'file-earmark-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-minus')),
  'file-earmark-music-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-music-fill')),
  'file-earmark-music': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-music')),
  'file-earmark-pdf-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-pdf-fill')),
  'file-earmark-pdf': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-pdf')),
  'file-earmark-person-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-person-fill')),
  'file-earmark-person': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-person')),
  'file-earmark-play-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-play-fill')),
  'file-earmark-play': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-play')),
  'file-earmark-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-plus-fill')),
  'file-earmark-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-plus')),
  'file-earmark-post-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-post-fill')),
  'file-earmark-post': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-post')),
  'file-earmark-ppt-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-ppt-fill')),
  'file-earmark-ppt': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-ppt')),
  'file-earmark-richtext-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-richtext-fill')),
  'file-earmark-richtext': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-richtext')),
  'file-earmark-ruled-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-ruled-fill')),
  'file-earmark-ruled': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-ruled')),
  'file-earmark-slides-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-slides-fill')),
  'file-earmark-slides': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-slides')),
  'file-earmark-spreadsheet-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/file-earmark-spreadsheet-fill')
  ),
  'file-earmark-spreadsheet': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-spreadsheet')),
  'file-earmark-text-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-text-fill')),
  'file-earmark-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-text')),
  'file-earmark-word-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-word-fill')),
  'file-earmark-word': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-word')),
  'file-earmark-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-x-fill')),
  'file-earmark-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-x')),
  'file-earmark-zip-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-zip-fill')),
  'file-earmark-zip': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark-zip')),
  'file-earmark': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-earmark')),
  'file-easel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-easel-fill')),
  'file-easel': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-easel')),
  'file-excel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-excel-fill')),
  'file-excel': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-excel')),
  'file-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-fill')),
  'file-font-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-font-fill')),
  'file-font': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-font')),
  'file-image-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-image-fill')),
  'file-image': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-image')),
  'file-lock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-lock-fill')),
  'file-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-lock')),
  'file-lock2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-lock2-fill')),
  'file-lock2': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-lock2')),
  'file-medical-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-medical-fill')),
  'file-medical': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-medical')),
  'file-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-minus-fill')),
  'file-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-minus')),
  'file-music-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-music-fill')),
  'file-music': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-music')),
  'file-pdf-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-pdf-fill')),
  'file-pdf': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-pdf')),
  'file-person-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-person-fill')),
  'file-person': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-person')),
  'file-play-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-play-fill')),
  'file-play': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-play')),
  'file-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-plus-fill')),
  'file-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-plus')),
  'file-post-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-post-fill')),
  'file-post': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-post')),
  'file-ppt-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-ppt-fill')),
  'file-ppt': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-ppt')),
  'file-richtext-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-richtext-fill')),
  'file-richtext': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-richtext')),
  'file-ruled-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-ruled-fill')),
  'file-ruled': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-ruled')),
  'file-slides-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-slides-fill')),
  'file-slides': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-slides')),
  'file-spreadsheet-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-spreadsheet-fill')),
  'file-spreadsheet': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-spreadsheet')),
  'file-text-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-text-fill')),
  'file-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-text')),
  'file-word-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-word-fill')),
  'file-word': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-word')),
  'file-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-x-fill')),
  'file-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-x')),
  'file-zip-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-zip-fill')),
  'file-zip': React.lazy(() => import('react-bootstrap-icons/dist/icons/file-zip')),
  file: React.lazy(() => import('react-bootstrap-icons/dist/icons/file')),
  'files-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/files-alt')),
  files: React.lazy(() => import('react-bootstrap-icons/dist/icons/files')),
  'filetype-aac': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-aac')),
  'filetype-ai': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-ai')),
  'filetype-bmp': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-bmp')),
  'filetype-cs': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-cs')),
  'filetype-css': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-css')),
  'filetype-csv': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-csv')),
  'filetype-doc': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-doc')),
  'filetype-docx': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-docx')),
  'filetype-exe': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-exe')),
  'filetype-gif': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-gif')),
  'filetype-heic': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-heic')),
  'filetype-html': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-html')),
  'filetype-java': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-java')),
  'filetype-jpg': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-jpg')),
  'filetype-js': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-js')),
  'filetype-json': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-json')),
  'filetype-jsx': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-jsx')),
  'filetype-key': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-key')),
  'filetype-m4p': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-m4p')),
  'filetype-md': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-md')),
  'filetype-mdx': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-mdx')),
  'filetype-mov': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-mov')),
  'filetype-mp3': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-mp3')),
  'filetype-mp4': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-mp4')),
  'filetype-otf': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-otf')),
  'filetype-pdf': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-pdf')),
  'filetype-php': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-php')),
  'filetype-png': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-png')),
  'filetype-ppt': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-ppt')),
  'filetype-pptx': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-pptx')),
  'filetype-psd': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-psd')),
  'filetype-py': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-py')),
  'filetype-raw': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-raw')),
  'filetype-rb': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-rb')),
  'filetype-sass': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-sass')),
  'filetype-scss': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-scss')),
  'filetype-sh': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-sh')),
  'filetype-sql': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-sql')),
  'filetype-svg': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-svg')),
  'filetype-tiff': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-tiff')),
  'filetype-tsx': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-tsx')),
  'filetype-ttf': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-ttf')),
  'filetype-txt': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-txt')),
  'filetype-wav': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-wav')),
  'filetype-woff': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-woff')),
  'filetype-xls': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-xls')),
  'filetype-xlsx': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-xlsx')),
  'filetype-xml': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-xml')),
  'filetype-yml': React.lazy(() => import('react-bootstrap-icons/dist/icons/filetype-yml')),
  film: React.lazy(() => import('react-bootstrap-icons/dist/icons/film')),
  'filter-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/filter-circle-fill')),
  'filter-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/filter-circle')),
  'filter-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/filter-left')),
  'filter-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/filter-right')),
  'filter-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/filter-square-fill')),
  'filter-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/filter-square')),
  filter: React.lazy(() => import('react-bootstrap-icons/dist/icons/filter')),
  fingerprint: React.lazy(() => import('react-bootstrap-icons/dist/icons/fingerprint')),
  fire: React.lazy(() => import('react-bootstrap-icons/dist/icons/fire')),
  'flag-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/flag-fill')),
  flag: React.lazy(() => import('react-bootstrap-icons/dist/icons/flag')),
  flower1: React.lazy(() => import('react-bootstrap-icons/dist/icons/flower1')),
  flower2: React.lazy(() => import('react-bootstrap-icons/dist/icons/flower2')),
  flower3: React.lazy(() => import('react-bootstrap-icons/dist/icons/flower3')),
  'folder-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-check')),
  'folder-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-fill')),
  'folder-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-minus')),
  'folder-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-plus')),
  'folder-symlink-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-symlink-fill')),
  'folder-symlink': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-symlink')),
  'folder-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder-x')),
  folder: React.lazy(() => import('react-bootstrap-icons/dist/icons/folder')),
  'folder2-open': React.lazy(() => import('react-bootstrap-icons/dist/icons/folder2-open')),
  folder2: React.lazy(() => import('react-bootstrap-icons/dist/icons/folder2')),
  fonts: React.lazy(() => import('react-bootstrap-icons/dist/icons/fonts')),
  'forward-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/forward-fill')),
  forward: React.lazy(() => import('react-bootstrap-icons/dist/icons/forward')),
  front: React.lazy(() => import('react-bootstrap-icons/dist/icons/front')),
  'fuel-pump-diesel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/fuel-pump-diesel-fill')),
  'fuel-pump-diesel': React.lazy(() => import('react-bootstrap-icons/dist/icons/fuel-pump-diesel')),
  'fuel-pump-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/fuel-pump-fill')),
  'fuel-pump': React.lazy(() => import('react-bootstrap-icons/dist/icons/fuel-pump')),
  'fullscreen-exit': React.lazy(() => import('react-bootstrap-icons/dist/icons/fullscreen-exit')),
  fullscreen: React.lazy(() => import('react-bootstrap-icons/dist/icons/fullscreen')),
  'funnel-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/funnel-fill')),
  funnel: React.lazy(() => import('react-bootstrap-icons/dist/icons/funnel')),
  'gear-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/gear-fill')),
  'gear-wide-connected': React.lazy(() => import('react-bootstrap-icons/dist/icons/gear-wide-connected')),
  'gear-wide': React.lazy(() => import('react-bootstrap-icons/dist/icons/gear-wide')),
  gear: React.lazy(() => import('react-bootstrap-icons/dist/icons/gear')),
  gem: React.lazy(() => import('react-bootstrap-icons/dist/icons/gem')),
  'gender-ambiguous': React.lazy(() => import('react-bootstrap-icons/dist/icons/gender-ambiguous')),
  'gender-female': React.lazy(() => import('react-bootstrap-icons/dist/icons/gender-female')),
  'gender-male': React.lazy(() => import('react-bootstrap-icons/dist/icons/gender-male')),
  'gender-trans': React.lazy(() => import('react-bootstrap-icons/dist/icons/gender-trans')),
  'geo-alt-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/geo-alt-fill')),
  'geo-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/geo-alt')),
  'geo-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/geo-fill')),
  geo: React.lazy(() => import('react-bootstrap-icons/dist/icons/geo')),
  'gift-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/gift-fill')),
  gift: React.lazy(() => import('react-bootstrap-icons/dist/icons/gift')),
  git: React.lazy(() => import('react-bootstrap-icons/dist/icons/git')),
  github: React.lazy(() => import('react-bootstrap-icons/dist/icons/github')),
  'globe-americas': React.lazy(() => import('react-bootstrap-icons/dist/icons/globe-americas')),
  'globe-asia-australia': React.lazy(() => import('react-bootstrap-icons/dist/icons/globe-asia-australia')),
  'globe-central-south-asia': React.lazy(() => import('react-bootstrap-icons/dist/icons/globe-central-south-asia')),
  'globe-europe-africa': React.lazy(() => import('react-bootstrap-icons/dist/icons/globe-europe-africa')),
  globe: React.lazy(() => import('react-bootstrap-icons/dist/icons/globe')),
  globe2: React.lazy(() => import('react-bootstrap-icons/dist/icons/globe2')),
  'google-play': React.lazy(() => import('react-bootstrap-icons/dist/icons/google-play')),
  google: React.lazy(() => import('react-bootstrap-icons/dist/icons/google')),
  'gpu-card': React.lazy(() => import('react-bootstrap-icons/dist/icons/gpu-card')),
  'graph-down-arrow': React.lazy(() => import('react-bootstrap-icons/dist/icons/graph-down-arrow')),
  'graph-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/graph-down')),
  'graph-up-arrow': React.lazy(() => import('react-bootstrap-icons/dist/icons/graph-up-arrow')),
  'graph-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/graph-up')),
  'grid-1x2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-1x2-fill')),
  'grid-1x2': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-1x2')),
  'grid-3x2-gap-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-3x2-gap-fill')),
  'grid-3x2-gap': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-3x2-gap')),
  'grid-3x2': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-3x2')),
  'grid-3x3-gap-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-3x3-gap-fill')),
  'grid-3x3-gap': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-3x3-gap')),
  'grid-3x3': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-3x3')),
  'grid-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/grid-fill')),
  grid: React.lazy(() => import('react-bootstrap-icons/dist/icons/grid')),
  'grip-horizontal': React.lazy(() => import('react-bootstrap-icons/dist/icons/grip-horizontal')),
  'grip-vertical': React.lazy(() => import('react-bootstrap-icons/dist/icons/grip-vertical')),
  'h-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/h-circle-fill')),
  'h-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/h-circle')),
  'h-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/h-square-fill')),
  'h-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/h-square')),
  hammer: React.lazy(() => import('react-bootstrap-icons/dist/icons/hammer')),
  'hand-index-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-index-fill')),
  'hand-index-thumb-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-index-thumb-fill')),
  'hand-index-thumb': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-index-thumb')),
  'hand-index': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-index')),
  'hand-thumbs-down-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-thumbs-down-fill')),
  'hand-thumbs-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-thumbs-down')),
  'hand-thumbs-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-thumbs-up-fill')),
  'hand-thumbs-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/hand-thumbs-up')),
  'handbag-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/handbag-fill')),
  handbag: React.lazy(() => import('react-bootstrap-icons/dist/icons/handbag')),
  hash: React.lazy(() => import('react-bootstrap-icons/dist/icons/hash')),
  'hdd-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-fill')),
  'hdd-network-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-network-fill')),
  'hdd-network': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-network')),
  'hdd-rack-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-rack-fill')),
  'hdd-rack': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-rack')),
  'hdd-stack-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-stack-fill')),
  'hdd-stack': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd-stack')),
  hdd: React.lazy(() => import('react-bootstrap-icons/dist/icons/hdd')),
  'hdmi-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hdmi-fill')),
  hdmi: React.lazy(() => import('react-bootstrap-icons/dist/icons/hdmi')),
  headphones: React.lazy(() => import('react-bootstrap-icons/dist/icons/headphones')),
  'headset-vr': React.lazy(() => import('react-bootstrap-icons/dist/icons/headset-vr')),
  headset: React.lazy(() => import('react-bootstrap-icons/dist/icons/headset')),
  'heart-arrow': React.lazy(() => import('react-bootstrap-icons/dist/icons/heart-arrow')),
  'heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/heart-fill')),
  'heart-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/heart-half')),
  'heart-pulse-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/heart-pulse-fill')),
  'heart-pulse': React.lazy(() => import('react-bootstrap-icons/dist/icons/heart-pulse')),
  heart: React.lazy(() => import('react-bootstrap-icons/dist/icons/heart')),
  'heartbreak-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/heartbreak-fill')),
  heartbreak: React.lazy(() => import('react-bootstrap-icons/dist/icons/heartbreak')),
  hearts: React.lazy(() => import('react-bootstrap-icons/dist/icons/hearts')),
  'heptagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/heptagon-fill')),
  'heptagon-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/heptagon-half')),
  heptagon: React.lazy(() => import('react-bootstrap-icons/dist/icons/heptagon')),
  'hexagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hexagon-fill')),
  'hexagon-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/hexagon-half')),
  hexagon: React.lazy(() => import('react-bootstrap-icons/dist/icons/hexagon')),
  'hospital-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/hospital-fill')),
  hospital: React.lazy(() => import('react-bootstrap-icons/dist/icons/hospital')),
  'hourglass-bottom': React.lazy(() => import('react-bootstrap-icons/dist/icons/hourglass-bottom')),
  'hourglass-split': React.lazy(() => import('react-bootstrap-icons/dist/icons/hourglass-split')),
  'hourglass-top': React.lazy(() => import('react-bootstrap-icons/dist/icons/hourglass-top')),
  hourglass: React.lazy(() => import('react-bootstrap-icons/dist/icons/hourglass')),
  'house-add-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-add-fill')),
  'house-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-add')),
  'house-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-check-fill')),
  'house-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-check')),
  'house-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-dash-fill')),
  'house-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-dash')),
  'house-door-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-door-fill')),
  'house-door': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-door')),
  'house-down-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-down-fill')),
  'house-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-down')),
  'house-exclamation-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-exclamation-fill')),
  'house-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-exclamation')),
  'house-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-fill')),
  'house-gear-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-gear-fill')),
  'house-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-gear')),
  'house-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-heart-fill')),
  'house-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-heart')),
  'house-lock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-lock-fill')),
  'house-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-lock')),
  'house-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-slash-fill')),
  'house-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-slash')),
  'house-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-up-fill')),
  'house-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-up')),
  'house-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-x-fill')),
  'house-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/house-x')),
  house: React.lazy(() => import('react-bootstrap-icons/dist/icons/house')),
  'houses-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/houses-fill')),
  houses: React.lazy(() => import('react-bootstrap-icons/dist/icons/houses')),
  hr: React.lazy(() => import('react-bootstrap-icons/dist/icons/hr')),
  hurricane: React.lazy(() => import('react-bootstrap-icons/dist/icons/hurricane')),
  hypnotize: React.lazy(() => import('react-bootstrap-icons/dist/icons/hypnotize')),
  'image-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/image-alt')),
  'image-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/image-fill')),
  image: React.lazy(() => import('react-bootstrap-icons/dist/icons/image')),
  images: React.lazy(() => import('react-bootstrap-icons/dist/icons/images')),
  'inbox-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/inbox-fill')),
  inbox: React.lazy(() => import('react-bootstrap-icons/dist/icons/inbox')),
  'inboxes-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/inboxes-fill')),
  inboxes: React.lazy(() => import('react-bootstrap-icons/dist/icons/inboxes')),
  incognito: React.lazy(() => import('react-bootstrap-icons/dist/icons/incognito')),
  indent: React.lazy(() => import('react-bootstrap-icons/dist/icons/indent')),
  infinity: React.lazy(() => import('react-bootstrap-icons/dist/icons/infinity')),
  'info-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/info-circle-fill')),
  'info-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/info-circle')),
  'info-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/info-lg')),
  'info-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/info-square-fill')),
  'info-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/info-square')),
  info: React.lazy(() => import('react-bootstrap-icons/dist/icons/info')),
  'input-cursor-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/input-cursor-text')),
  'input-cursor': React.lazy(() => import('react-bootstrap-icons/dist/icons/input-cursor')),
  instagram: React.lazy(() => import('react-bootstrap-icons/dist/icons/instagram')),
  intersect: React.lazy(() => import('react-bootstrap-icons/dist/icons/intersect')),
  'journal-album': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-album')),
  'journal-arrow-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-arrow-down')),
  'journal-arrow-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-arrow-up')),
  'journal-bookmark-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-bookmark-fill')),
  'journal-bookmark': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-bookmark')),
  'journal-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-check')),
  'journal-code': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-code')),
  'journal-medical': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-medical')),
  'journal-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-minus')),
  'journal-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-plus')),
  'journal-richtext': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-richtext')),
  'journal-text': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-text')),
  'journal-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/journal-x')),
  journal: React.lazy(() => import('react-bootstrap-icons/dist/icons/journal')),
  journals: React.lazy(() => import('react-bootstrap-icons/dist/icons/journals')),
  joystick: React.lazy(() => import('react-bootstrap-icons/dist/icons/joystick')),
  'justify-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/justify-left')),
  'justify-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/justify-right')),
  justify: React.lazy(() => import('react-bootstrap-icons/dist/icons/justify')),
  'kanban-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/kanban-fill')),
  kanban: React.lazy(() => import('react-bootstrap-icons/dist/icons/kanban')),
  'key-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/key-fill')),
  key: React.lazy(() => import('react-bootstrap-icons/dist/icons/key')),
  'keyboard-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/keyboard-fill')),
  keyboard: React.lazy(() => import('react-bootstrap-icons/dist/icons/keyboard')),
  ladder: React.lazy(() => import('react-bootstrap-icons/dist/icons/ladder')),
  'lamp-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lamp-fill')),
  lamp: React.lazy(() => import('react-bootstrap-icons/dist/icons/lamp')),
  'laptop-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/laptop-fill')),
  laptop: React.lazy(() => import('react-bootstrap-icons/dist/icons/laptop')),
  'layer-backward': React.lazy(() => import('react-bootstrap-icons/dist/icons/layer-backward')),
  'layer-forward': React.lazy(() => import('react-bootstrap-icons/dist/icons/layer-forward')),
  'layers-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/layers-fill')),
  'layers-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/layers-half')),
  layers: React.lazy(() => import('react-bootstrap-icons/dist/icons/layers')),
  'layout-sidebar-inset-reverse': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/layout-sidebar-inset-reverse')
  ),
  'layout-sidebar-inset': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-sidebar-inset')),
  'layout-sidebar-reverse': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-sidebar-reverse')),
  'layout-sidebar': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-sidebar')),
  'layout-split': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-split')),
  'layout-text-sidebar-reverse': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/layout-text-sidebar-reverse')
  ),
  'layout-text-sidebar': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-text-sidebar')),
  'layout-text-window-reverse': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-text-window-reverse')),
  'layout-text-window': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-text-window')),
  'layout-three-columns': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-three-columns')),
  'layout-wtf': React.lazy(() => import('react-bootstrap-icons/dist/icons/layout-wtf')),
  'life-preserver': React.lazy(() => import('react-bootstrap-icons/dist/icons/life-preserver')),
  'lightbulb-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lightbulb-fill')),
  'lightbulb-off-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lightbulb-off-fill')),
  'lightbulb-off': React.lazy(() => import('react-bootstrap-icons/dist/icons/lightbulb-off')),
  lightbulb: React.lazy(() => import('react-bootstrap-icons/dist/icons/lightbulb')),
  'lightning-charge-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lightning-charge-fill')),
  'lightning-charge': React.lazy(() => import('react-bootstrap-icons/dist/icons/lightning-charge')),
  'lightning-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lightning-fill')),
  lightning: React.lazy(() => import('react-bootstrap-icons/dist/icons/lightning')),
  line: React.lazy(() => import('react-bootstrap-icons/dist/icons/line')),
  'link-45deg': React.lazy(() => import('react-bootstrap-icons/dist/icons/link-45deg')),
  link: React.lazy(() => import('react-bootstrap-icons/dist/icons/link')),
  linkedin: React.lazy(() => import('react-bootstrap-icons/dist/icons/linkedin')),
  'list-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-check')),
  'list-columns-reverse': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-columns-reverse')),
  'list-columns': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-columns')),
  'list-nested': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-nested')),
  'list-ol': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-ol')),
  'list-stars': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-stars')),
  'list-task': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-task')),
  'list-ul': React.lazy(() => import('react-bootstrap-icons/dist/icons/list-ul')),
  list: React.lazy(() => import('react-bootstrap-icons/dist/icons/list')),
  'lock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lock-fill')),
  lock: React.lazy(() => import('react-bootstrap-icons/dist/icons/lock')),
  'lungs-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/lungs-fill')),
  lungs: React.lazy(() => import('react-bootstrap-icons/dist/icons/lungs')),
  magic: React.lazy(() => import('react-bootstrap-icons/dist/icons/magic')),
  'magnet-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/magnet-fill')),
  magnet: React.lazy(() => import('react-bootstrap-icons/dist/icons/magnet')),
  mailbox: React.lazy(() => import('react-bootstrap-icons/dist/icons/mailbox')),
  mailbox2: React.lazy(() => import('react-bootstrap-icons/dist/icons/mailbox2')),
  'map-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/map-fill')),
  map: React.lazy(() => import('react-bootstrap-icons/dist/icons/map')),
  'markdown-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/markdown-fill')),
  markdown: React.lazy(() => import('react-bootstrap-icons/dist/icons/markdown')),
  mask: React.lazy(() => import('react-bootstrap-icons/dist/icons/mask')),
  mastodon: React.lazy(() => import('react-bootstrap-icons/dist/icons/mastodon')),
  medium: React.lazy(() => import('react-bootstrap-icons/dist/icons/medium')),
  'megaphone-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/megaphone-fill')),
  megaphone: React.lazy(() => import('react-bootstrap-icons/dist/icons/megaphone')),
  memory: React.lazy(() => import('react-bootstrap-icons/dist/icons/memory')),
  'menu-app-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-app-fill')),
  'menu-app': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-app')),
  'menu-button-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-button-fill')),
  'menu-button-wide-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-button-wide-fill')),
  'menu-button-wide': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-button-wide')),
  'menu-button': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-button')),
  'menu-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-down')),
  'menu-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/menu-up')),
  messenger: React.lazy(() => import('react-bootstrap-icons/dist/icons/messenger')),
  meta: React.lazy(() => import('react-bootstrap-icons/dist/icons/meta')),
  'mic-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/mic-fill')),
  'mic-mute-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/mic-mute-fill')),
  'mic-mute': React.lazy(() => import('react-bootstrap-icons/dist/icons/mic-mute')),
  mic: React.lazy(() => import('react-bootstrap-icons/dist/icons/mic')),
  'microsoft-teams': React.lazy(() => import('react-bootstrap-icons/dist/icons/microsoft-teams')),
  microsoft: React.lazy(() => import('react-bootstrap-icons/dist/icons/microsoft')),
  'minecart-loaded': React.lazy(() => import('react-bootstrap-icons/dist/icons/minecart-loaded')),
  minecart: React.lazy(() => import('react-bootstrap-icons/dist/icons/minecart')),
  'modem-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/modem-fill')),
  modem: React.lazy(() => import('react-bootstrap-icons/dist/icons/modem')),
  moisture: React.lazy(() => import('react-bootstrap-icons/dist/icons/moisture')),
  'moon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/moon-fill')),
  'moon-stars-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/moon-stars-fill')),
  'moon-stars': React.lazy(() => import('react-bootstrap-icons/dist/icons/moon-stars')),
  moon: React.lazy(() => import('react-bootstrap-icons/dist/icons/moon')),
  'mortarboard-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/mortarboard-fill')),
  mortarboard: React.lazy(() => import('react-bootstrap-icons/dist/icons/mortarboard')),
  'motherboard-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/motherboard-fill')),
  motherboard: React.lazy(() => import('react-bootstrap-icons/dist/icons/motherboard')),
  'mouse-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/mouse-fill')),
  mouse: React.lazy(() => import('react-bootstrap-icons/dist/icons/mouse')),
  'mouse2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/mouse2-fill')),
  mouse2: React.lazy(() => import('react-bootstrap-icons/dist/icons/mouse2')),
  'mouse3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/mouse3-fill')),
  mouse3: React.lazy(() => import('react-bootstrap-icons/dist/icons/mouse3')),
  'music-note-beamed': React.lazy(() => import('react-bootstrap-icons/dist/icons/music-note-beamed')),
  'music-note-list': React.lazy(() => import('react-bootstrap-icons/dist/icons/music-note-list')),
  'music-note': React.lazy(() => import('react-bootstrap-icons/dist/icons/music-note')),
  'music-player-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/music-player-fill')),
  'music-player': React.lazy(() => import('react-bootstrap-icons/dist/icons/music-player')),
  newspaper: React.lazy(() => import('react-bootstrap-icons/dist/icons/newspaper')),
  'nintendo-switch': React.lazy(() => import('react-bootstrap-icons/dist/icons/nintendo-switch')),
  'node-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/node-minus-fill')),
  'node-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/node-minus')),
  'node-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/node-plus-fill')),
  'node-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/node-plus')),
  'nut-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/nut-fill')),
  nut: React.lazy(() => import('react-bootstrap-icons/dist/icons/nut')),
  nvidia: React.lazy(() => import('react-bootstrap-icons/dist/icons/nvidia')),
  'octagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/octagon-fill')),
  'octagon-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/octagon-half')),
  octagon: React.lazy(() => import('react-bootstrap-icons/dist/icons/octagon')),
  'optical-audio-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/optical-audio-fill')),
  'optical-audio': React.lazy(() => import('react-bootstrap-icons/dist/icons/optical-audio')),
  option: React.lazy(() => import('react-bootstrap-icons/dist/icons/option')),
  outlet: React.lazy(() => import('react-bootstrap-icons/dist/icons/outlet')),
  'p-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/p-circle-fill')),
  'p-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/p-circle')),
  'p-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/p-square-fill')),
  'p-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/p-square')),
  'paint-bucket': React.lazy(() => import('react-bootstrap-icons/dist/icons/paint-bucket')),
  'palette-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/palette-fill')),
  palette: React.lazy(() => import('react-bootstrap-icons/dist/icons/palette')),
  palette2: React.lazy(() => import('react-bootstrap-icons/dist/icons/palette2')),
  paperclip: React.lazy(() => import('react-bootstrap-icons/dist/icons/paperclip')),
  paragraph: React.lazy(() => import('react-bootstrap-icons/dist/icons/paragraph')),
  'pass-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pass-fill')),
  pass: React.lazy(() => import('react-bootstrap-icons/dist/icons/pass')),
  'patch-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-check-fill')),
  'patch-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-check')),
  'patch-exclamation-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-exclamation-fill')),
  'patch-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-exclamation')),
  'patch-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-minus-fill')),
  'patch-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-minus')),
  'patch-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-plus-fill')),
  'patch-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-plus')),
  'patch-question-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-question-fill')),
  'patch-question': React.lazy(() => import('react-bootstrap-icons/dist/icons/patch-question')),
  'pause-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pause-btn-fill')),
  'pause-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/pause-btn')),
  'pause-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pause-circle-fill')),
  'pause-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/pause-circle')),
  'pause-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pause-fill')),
  pause: React.lazy(() => import('react-bootstrap-icons/dist/icons/pause')),
  paypal: React.lazy(() => import('react-bootstrap-icons/dist/icons/paypal')),
  'pc-display-horizontal': React.lazy(() => import('react-bootstrap-icons/dist/icons/pc-display-horizontal')),
  'pc-display': React.lazy(() => import('react-bootstrap-icons/dist/icons/pc-display')),
  'pc-horizontal': React.lazy(() => import('react-bootstrap-icons/dist/icons/pc-horizontal')),
  pc: React.lazy(() => import('react-bootstrap-icons/dist/icons/pc')),
  'pci-card': React.lazy(() => import('react-bootstrap-icons/dist/icons/pci-card')),
  'peace-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/peace-fill')),
  peace: React.lazy(() => import('react-bootstrap-icons/dist/icons/peace')),
  'pen-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pen-fill')),
  pen: React.lazy(() => import('react-bootstrap-icons/dist/icons/pen')),
  'pencil-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pencil-fill')),
  'pencil-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/pencil-square')),
  pencil: React.lazy(() => import('react-bootstrap-icons/dist/icons/pencil')),
  'pentagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pentagon-fill')),
  'pentagon-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/pentagon-half')),
  pentagon: React.lazy(() => import('react-bootstrap-icons/dist/icons/pentagon')),
  'people-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/people-fill')),
  people: React.lazy(() => import('react-bootstrap-icons/dist/icons/people')),
  percent: React.lazy(() => import('react-bootstrap-icons/dist/icons/percent')),
  'person-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-add')),
  'person-badge-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-badge-fill')),
  'person-badge': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-badge')),
  'person-bounding-box': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-bounding-box')),
  'person-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-check-fill')),
  'person-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-check')),
  'person-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-circle')),
  'person-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-dash-fill')),
  'person-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-dash')),
  'person-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-down')),
  'person-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-exclamation')),
  'person-fill-add': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-add')),
  'person-fill-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-check')),
  'person-fill-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-dash')),
  'person-fill-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-down')),
  'person-fill-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-exclamation')),
  'person-fill-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-gear')),
  'person-fill-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-lock')),
  'person-fill-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-slash')),
  'person-fill-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-up')),
  'person-fill-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill-x')),
  'person-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-fill')),
  'person-gear': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-gear')),
  'person-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-heart')),
  'person-hearts': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-hearts')),
  'person-lines-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-lines-fill')),
  'person-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-lock')),
  'person-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-plus-fill')),
  'person-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-plus')),
  'person-rolodex': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-rolodex')),
  'person-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-slash')),
  'person-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-square')),
  'person-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-up')),
  'person-vcard-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-vcard-fill')),
  'person-vcard': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-vcard')),
  'person-video': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-video')),
  'person-video2': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-video2')),
  'person-video3': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-video3')),
  'person-workspace': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-workspace')),
  'person-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-x-fill')),
  'person-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/person-x')),
  person: React.lazy(() => import('react-bootstrap-icons/dist/icons/person')),
  'phone-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/phone-fill')),
  'phone-flip': React.lazy(() => import('react-bootstrap-icons/dist/icons/phone-flip')),
  'phone-landscape-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/phone-landscape-fill')),
  'phone-landscape': React.lazy(() => import('react-bootstrap-icons/dist/icons/phone-landscape')),
  'phone-vibrate-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/phone-vibrate-fill')),
  'phone-vibrate': React.lazy(() => import('react-bootstrap-icons/dist/icons/phone-vibrate')),
  phone: React.lazy(() => import('react-bootstrap-icons/dist/icons/phone')),
  'pie-chart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pie-chart-fill')),
  'pie-chart': React.lazy(() => import('react-bootstrap-icons/dist/icons/pie-chart')),
  'piggy-bank-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/piggy-bank-fill')),
  'piggy-bank': React.lazy(() => import('react-bootstrap-icons/dist/icons/piggy-bank')),
  'pin-angle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pin-angle-fill')),
  'pin-angle': React.lazy(() => import('react-bootstrap-icons/dist/icons/pin-angle')),
  'pin-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pin-fill')),
  'pin-map-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pin-map-fill')),
  'pin-map': React.lazy(() => import('react-bootstrap-icons/dist/icons/pin-map')),
  pin: React.lazy(() => import('react-bootstrap-icons/dist/icons/pin')),
  pinterest: React.lazy(() => import('react-bootstrap-icons/dist/icons/pinterest')),
  'pip-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/pip-fill')),
  pip: React.lazy(() => import('react-bootstrap-icons/dist/icons/pip')),
  'play-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/play-btn-fill')),
  'play-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/play-btn')),
  'play-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/play-circle-fill')),
  'play-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/play-circle')),
  'play-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/play-fill')),
  play: React.lazy(() => import('react-bootstrap-icons/dist/icons/play')),
  playstation: React.lazy(() => import('react-bootstrap-icons/dist/icons/playstation')),
  'plug-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/plug-fill')),
  plug: React.lazy(() => import('react-bootstrap-icons/dist/icons/plug')),
  plugin: React.lazy(() => import('react-bootstrap-icons/dist/icons/plugin')),
  'plus-circle-dotted': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-circle-dotted')),
  'plus-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-circle-fill')),
  'plus-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-circle')),
  'plus-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-lg')),
  'plus-slash-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-slash-minus')),
  'plus-square-dotted': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-square-dotted')),
  'plus-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-square-fill')),
  'plus-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/plus-square')),
  plus: React.lazy(() => import('react-bootstrap-icons/dist/icons/plus')),
  'postage-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/postage-fill')),
  'postage-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/postage-heart-fill')),
  'postage-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/postage-heart')),
  postage: React.lazy(() => import('react-bootstrap-icons/dist/icons/postage')),
  'postcard-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/postcard-fill')),
  'postcard-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/postcard-heart-fill')),
  'postcard-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/postcard-heart')),
  postcard: React.lazy(() => import('react-bootstrap-icons/dist/icons/postcard')),
  power: React.lazy(() => import('react-bootstrap-icons/dist/icons/power')),
  prescription: React.lazy(() => import('react-bootstrap-icons/dist/icons/prescription')),
  prescription2: React.lazy(() => import('react-bootstrap-icons/dist/icons/prescription2')),
  'printer-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/printer-fill')),
  printer: React.lazy(() => import('react-bootstrap-icons/dist/icons/printer')),
  'projector-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/projector-fill')),
  projector: React.lazy(() => import('react-bootstrap-icons/dist/icons/projector')),
  'puzzle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/puzzle-fill')),
  puzzle: React.lazy(() => import('react-bootstrap-icons/dist/icons/puzzle')),
  'qr-code-scan': React.lazy(() => import('react-bootstrap-icons/dist/icons/qr-code-scan')),
  'qr-code': React.lazy(() => import('react-bootstrap-icons/dist/icons/qr-code')),
  'question-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-circle-fill')),
  'question-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-circle')),
  'question-diamond-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-diamond-fill')),
  'question-diamond': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-diamond')),
  'question-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-lg')),
  'question-octagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-octagon-fill')),
  'question-octagon': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-octagon')),
  'question-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-square-fill')),
  'question-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/question-square')),
  question: React.lazy(() => import('react-bootstrap-icons/dist/icons/question')),
  quora: React.lazy(() => import('react-bootstrap-icons/dist/icons/quora')),
  quote: React.lazy(() => import('react-bootstrap-icons/dist/icons/quote')),
  'r-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/r-circle-fill')),
  'r-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/r-circle')),
  'r-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/r-square-fill')),
  'r-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/r-square')),
  radioactive: React.lazy(() => import('react-bootstrap-icons/dist/icons/radioactive')),
  rainbow: React.lazy(() => import('react-bootstrap-icons/dist/icons/rainbow')),
  'receipt-cutoff': React.lazy(() => import('react-bootstrap-icons/dist/icons/receipt-cutoff')),
  receipt: React.lazy(() => import('react-bootstrap-icons/dist/icons/receipt')),
  'reception-0': React.lazy(() => import('react-bootstrap-icons/dist/icons/reception-0')),
  'reception-1': React.lazy(() => import('react-bootstrap-icons/dist/icons/reception-1')),
  'reception-2': React.lazy(() => import('react-bootstrap-icons/dist/icons/reception-2')),
  'reception-3': React.lazy(() => import('react-bootstrap-icons/dist/icons/reception-3')),
  'reception-4': React.lazy(() => import('react-bootstrap-icons/dist/icons/reception-4')),
  'record-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/record-btn-fill')),
  'record-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/record-btn')),
  'record-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/record-circle-fill')),
  'record-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/record-circle')),
  'record-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/record-fill')),
  record: React.lazy(() => import('react-bootstrap-icons/dist/icons/record')),
  'record2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/record2-fill')),
  record2: React.lazy(() => import('react-bootstrap-icons/dist/icons/record2')),
  recycle: React.lazy(() => import('react-bootstrap-icons/dist/icons/recycle')),
  reddit: React.lazy(() => import('react-bootstrap-icons/dist/icons/reddit')),
  regex: React.lazy(() => import('react-bootstrap-icons/dist/icons/regex')),
  'repeat-1': React.lazy(() => import('react-bootstrap-icons/dist/icons/repeat-1')),
  repeat: React.lazy(() => import('react-bootstrap-icons/dist/icons/repeat')),
  'reply-all-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/reply-all-fill')),
  'reply-all': React.lazy(() => import('react-bootstrap-icons/dist/icons/reply-all')),
  'reply-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/reply-fill')),
  reply: React.lazy(() => import('react-bootstrap-icons/dist/icons/reply')),
  'rewind-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/rewind-btn-fill')),
  'rewind-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/rewind-btn')),
  'rewind-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/rewind-circle-fill')),
  'rewind-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/rewind-circle')),
  'rewind-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/rewind-fill')),
  rewind: React.lazy(() => import('react-bootstrap-icons/dist/icons/rewind')),
  robot: React.lazy(() => import('react-bootstrap-icons/dist/icons/robot')),
  'rocket-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/rocket-fill')),
  'rocket-takeoff-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/rocket-takeoff-fill')),
  'rocket-takeoff': React.lazy(() => import('react-bootstrap-icons/dist/icons/rocket-takeoff')),
  rocket: React.lazy(() => import('react-bootstrap-icons/dist/icons/rocket')),
  'router-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/router-fill')),
  router: React.lazy(() => import('react-bootstrap-icons/dist/icons/router')),
  'rss-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/rss-fill')),
  rss: React.lazy(() => import('react-bootstrap-icons/dist/icons/rss')),
  rulers: React.lazy(() => import('react-bootstrap-icons/dist/icons/rulers')),
  'safe-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/safe-fill')),
  safe: React.lazy(() => import('react-bootstrap-icons/dist/icons/safe')),
  'safe2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/safe2-fill')),
  safe2: React.lazy(() => import('react-bootstrap-icons/dist/icons/safe2')),
  'save-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/save-fill')),
  save: React.lazy(() => import('react-bootstrap-icons/dist/icons/save')),
  'save2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/save2-fill')),
  save2: React.lazy(() => import('react-bootstrap-icons/dist/icons/save2')),
  scissors: React.lazy(() => import('react-bootstrap-icons/dist/icons/scissors')),
  scooter: React.lazy(() => import('react-bootstrap-icons/dist/icons/scooter')),
  screwdriver: React.lazy(() => import('react-bootstrap-icons/dist/icons/screwdriver')),
  'sd-card-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sd-card-fill')),
  'sd-card': React.lazy(() => import('react-bootstrap-icons/dist/icons/sd-card')),
  'search-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/search-heart-fill')),
  'search-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/search-heart')),
  search: React.lazy(() => import('react-bootstrap-icons/dist/icons/search')),
  'segmented-nav': React.lazy(() => import('react-bootstrap-icons/dist/icons/segmented-nav')),
  'send-check-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-check-fill')),
  'send-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-check')),
  'send-dash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-dash-fill')),
  'send-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-dash')),
  'send-exclamation-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-exclamation-fill')),
  'send-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-exclamation')),
  'send-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-fill')),
  'send-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-plus-fill')),
  'send-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-plus')),
  'send-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-slash-fill')),
  'send-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-slash')),
  'send-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-x-fill')),
  'send-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/send-x')),
  send: React.lazy(() => import('react-bootstrap-icons/dist/icons/send')),
  server: React.lazy(() => import('react-bootstrap-icons/dist/icons/server')),
  'share-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/share-fill')),
  share: React.lazy(() => import('react-bootstrap-icons/dist/icons/share')),
  'shield-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-check')),
  'shield-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-exclamation')),
  'shield-fill-check': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-fill-check')),
  'shield-fill-exclamation': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-fill-exclamation')),
  'shield-fill-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-fill-minus')),
  'shield-fill-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-fill-plus')),
  'shield-fill-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-fill-x')),
  'shield-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-fill')),
  'shield-lock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-lock-fill')),
  'shield-lock': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-lock')),
  'shield-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-minus')),
  'shield-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-plus')),
  'shield-shaded': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-shaded')),
  'shield-slash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-slash-fill')),
  'shield-slash': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-slash')),
  'shield-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/shield-x')),
  shield: React.lazy(() => import('react-bootstrap-icons/dist/icons/shield')),
  'shift-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/shift-fill')),
  shift: React.lazy(() => import('react-bootstrap-icons/dist/icons/shift')),
  'shop-window': React.lazy(() => import('react-bootstrap-icons/dist/icons/shop-window')),
  shop: React.lazy(() => import('react-bootstrap-icons/dist/icons/shop')),
  shuffle: React.lazy(() => import('react-bootstrap-icons/dist/icons/shuffle')),
  'sign-dead-end-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-dead-end-fill')),
  'sign-dead-end': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-dead-end')),
  'sign-do-not-enter-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-do-not-enter-fill')),
  'sign-do-not-enter': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-do-not-enter')),
  'sign-intersection-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection-fill')),
  'sign-intersection-side-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/sign-intersection-side-fill')
  ),
  'sign-intersection-side': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection-side')),
  'sign-intersection-t-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection-t-fill')),
  'sign-intersection-t': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection-t')),
  'sign-intersection-y-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection-y-fill')),
  'sign-intersection-y': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection-y')),
  'sign-intersection': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-intersection')),
  'sign-merge-left-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-merge-left-fill')),
  'sign-merge-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-merge-left')),
  'sign-merge-right-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-merge-right-fill')),
  'sign-merge-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-merge-right')),
  'sign-no-left-turn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-no-left-turn-fill')),
  'sign-no-left-turn': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-no-left-turn')),
  'sign-no-parking-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-no-parking-fill')),
  'sign-no-parking': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-no-parking')),
  'sign-no-right-turn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-no-right-turn-fill')),
  'sign-no-right-turn': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-no-right-turn')),
  'sign-railroad-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-railroad-fill')),
  'sign-railroad': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-railroad')),
  'sign-stop-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-stop-fill')),
  'sign-stop-lights-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-stop-lights-fill')),
  'sign-stop-lights': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-stop-lights')),
  'sign-stop': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-stop')),
  'sign-turn-left-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-left-fill')),
  'sign-turn-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-left')),
  'sign-turn-right-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-right-fill')),
  'sign-turn-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-right')),
  'sign-turn-slight-left-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-slight-left-fill')),
  'sign-turn-slight-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-slight-left')),
  'sign-turn-slight-right-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/sign-turn-slight-right-fill')
  ),
  'sign-turn-slight-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-turn-slight-right')),
  'sign-yield-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-yield-fill')),
  'sign-yield': React.lazy(() => import('react-bootstrap-icons/dist/icons/sign-yield')),
  signal: React.lazy(() => import('react-bootstrap-icons/dist/icons/signal')),
  'signpost-2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/signpost-2-fill')),
  'signpost-2': React.lazy(() => import('react-bootstrap-icons/dist/icons/signpost-2')),
  'signpost-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/signpost-fill')),
  'signpost-split-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/signpost-split-fill')),
  'signpost-split': React.lazy(() => import('react-bootstrap-icons/dist/icons/signpost-split')),
  signpost: React.lazy(() => import('react-bootstrap-icons/dist/icons/signpost')),
  'sim-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sim-fill')),
  sim: React.lazy(() => import('react-bootstrap-icons/dist/icons/sim')),
  'sina-weibo': React.lazy(() => import('react-bootstrap-icons/dist/icons/sina-weibo')),
  'skip-backward-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-backward-btn-fill')),
  'skip-backward-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-backward-btn')),
  'skip-backward-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-backward-circle-fill')),
  'skip-backward-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-backward-circle')),
  'skip-backward-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-backward-fill')),
  'skip-backward': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-backward')),
  'skip-end-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-end-btn-fill')),
  'skip-end-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-end-btn')),
  'skip-end-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-end-circle-fill')),
  'skip-end-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-end-circle')),
  'skip-end-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-end-fill')),
  'skip-end': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-end')),
  'skip-forward-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-forward-btn-fill')),
  'skip-forward-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-forward-btn')),
  'skip-forward-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-forward-circle-fill')),
  'skip-forward-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-forward-circle')),
  'skip-forward-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-forward-fill')),
  'skip-forward': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-forward')),
  'skip-start-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-start-btn-fill')),
  'skip-start-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-start-btn')),
  'skip-start-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-start-circle-fill')),
  'skip-start-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-start-circle')),
  'skip-start-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-start-fill')),
  'skip-start': React.lazy(() => import('react-bootstrap-icons/dist/icons/skip-start')),
  skype: React.lazy(() => import('react-bootstrap-icons/dist/icons/skype')),
  slack: React.lazy(() => import('react-bootstrap-icons/dist/icons/slack')),
  'slash-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/slash-circle-fill')),
  'slash-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/slash-circle')),
  'slash-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/slash-lg')),
  'slash-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/slash-square-fill')),
  'slash-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/slash-square')),
  slash: React.lazy(() => import('react-bootstrap-icons/dist/icons/slash')),
  sliders: React.lazy(() => import('react-bootstrap-icons/dist/icons/sliders')),
  'sliders2-vertical': React.lazy(() => import('react-bootstrap-icons/dist/icons/sliders2-vertical')),
  sliders2: React.lazy(() => import('react-bootstrap-icons/dist/icons/sliders2')),
  smartwatch: React.lazy(() => import('react-bootstrap-icons/dist/icons/smartwatch')),
  snapchat: React.lazy(() => import('react-bootstrap-icons/dist/icons/snapchat')),
  snow: React.lazy(() => import('react-bootstrap-icons/dist/icons/snow')),
  snow2: React.lazy(() => import('react-bootstrap-icons/dist/icons/snow2')),
  snow3: React.lazy(() => import('react-bootstrap-icons/dist/icons/snow3')),
  'sort-alpha-down-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-alpha-down-alt')),
  'sort-alpha-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-alpha-down')),
  'sort-alpha-up-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-alpha-up-alt')),
  'sort-alpha-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-alpha-up')),
  'sort-down-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-down-alt')),
  'sort-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-down')),
  'sort-numeric-down-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-numeric-down-alt')),
  'sort-numeric-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-numeric-down')),
  'sort-numeric-up-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-numeric-up-alt')),
  'sort-numeric-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-numeric-up')),
  'sort-up-alt': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-up-alt')),
  'sort-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/sort-up')),
  soundwave: React.lazy(() => import('react-bootstrap-icons/dist/icons/soundwave')),
  'speaker-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/speaker-fill')),
  speaker: React.lazy(() => import('react-bootstrap-icons/dist/icons/speaker')),
  speedometer: React.lazy(() => import('react-bootstrap-icons/dist/icons/speedometer')),
  speedometer2: React.lazy(() => import('react-bootstrap-icons/dist/icons/speedometer2')),
  spellcheck: React.lazy(() => import('react-bootstrap-icons/dist/icons/spellcheck')),
  spotify: React.lazy(() => import('react-bootstrap-icons/dist/icons/spotify')),
  'square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/square-fill')),
  'square-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/square-half')),
  square: React.lazy(() => import('react-bootstrap-icons/dist/icons/square')),
  'stack-overflow': React.lazy(() => import('react-bootstrap-icons/dist/icons/stack-overflow')),
  stack: React.lazy(() => import('react-bootstrap-icons/dist/icons/stack')),
  'star-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/star-fill')),
  'star-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/star-half')),
  star: React.lazy(() => import('react-bootstrap-icons/dist/icons/star')),
  stars: React.lazy(() => import('react-bootstrap-icons/dist/icons/stars')),
  steam: React.lazy(() => import('react-bootstrap-icons/dist/icons/steam')),
  'stickies-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/stickies-fill')),
  stickies: React.lazy(() => import('react-bootstrap-icons/dist/icons/stickies')),
  'sticky-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sticky-fill')),
  sticky: React.lazy(() => import('react-bootstrap-icons/dist/icons/sticky')),
  'stop-btn-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/stop-btn-fill')),
  'stop-btn': React.lazy(() => import('react-bootstrap-icons/dist/icons/stop-btn')),
  'stop-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/stop-circle-fill')),
  'stop-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/stop-circle')),
  'stop-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/stop-fill')),
  stop: React.lazy(() => import('react-bootstrap-icons/dist/icons/stop')),
  'stoplights-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/stoplights-fill')),
  stoplights: React.lazy(() => import('react-bootstrap-icons/dist/icons/stoplights')),
  'stopwatch-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/stopwatch-fill')),
  stopwatch: React.lazy(() => import('react-bootstrap-icons/dist/icons/stopwatch')),
  strava: React.lazy(() => import('react-bootstrap-icons/dist/icons/strava')),
  stripe: React.lazy(() => import('react-bootstrap-icons/dist/icons/stripe')),
  subscript: React.lazy(() => import('react-bootstrap-icons/dist/icons/subscript')),
  subtract: React.lazy(() => import('react-bootstrap-icons/dist/icons/subtract')),
  'suit-club-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-club-fill')),
  'suit-club': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-club')),
  'suit-diamond-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-diamond-fill')),
  'suit-diamond': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-diamond')),
  'suit-heart-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-heart-fill')),
  'suit-heart': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-heart')),
  'suit-spade-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-spade-fill')),
  'suit-spade': React.lazy(() => import('react-bootstrap-icons/dist/icons/suit-spade')),
  'sun-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sun-fill')),
  sun: React.lazy(() => import('react-bootstrap-icons/dist/icons/sun')),
  sunglasses: React.lazy(() => import('react-bootstrap-icons/dist/icons/sunglasses')),
  'sunrise-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sunrise-fill')),
  sunrise: React.lazy(() => import('react-bootstrap-icons/dist/icons/sunrise')),
  'sunset-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/sunset-fill')),
  sunset: React.lazy(() => import('react-bootstrap-icons/dist/icons/sunset')),
  superscript: React.lazy(() => import('react-bootstrap-icons/dist/icons/superscript')),
  'symmetry-horizontal': React.lazy(() => import('react-bootstrap-icons/dist/icons/symmetry-horizontal')),
  'symmetry-vertical': React.lazy(() => import('react-bootstrap-icons/dist/icons/symmetry-vertical')),
  table: React.lazy(() => import('react-bootstrap-icons/dist/icons/table')),
  'tablet-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/tablet-fill')),
  'tablet-landscape-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/tablet-landscape-fill')),
  'tablet-landscape': React.lazy(() => import('react-bootstrap-icons/dist/icons/tablet-landscape')),
  tablet: React.lazy(() => import('react-bootstrap-icons/dist/icons/tablet')),
  'tag-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/tag-fill')),
  tag: React.lazy(() => import('react-bootstrap-icons/dist/icons/tag')),
  'tags-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/tags-fill')),
  tags: React.lazy(() => import('react-bootstrap-icons/dist/icons/tags')),
  'taxi-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/taxi-front-fill')),
  'taxi-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/taxi-front')),
  telegram: React.lazy(() => import('react-bootstrap-icons/dist/icons/telegram')),
  'telephone-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-fill')),
  'telephone-forward-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-forward-fill')),
  'telephone-forward': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-forward')),
  'telephone-inbound-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-inbound-fill')),
  'telephone-inbound': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-inbound')),
  'telephone-minus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-minus-fill')),
  'telephone-minus': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-minus')),
  'telephone-outbound-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-outbound-fill')),
  'telephone-outbound': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-outbound')),
  'telephone-plus-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-plus-fill')),
  'telephone-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-plus')),
  'telephone-x-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-x-fill')),
  'telephone-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone-x')),
  telephone: React.lazy(() => import('react-bootstrap-icons/dist/icons/telephone')),
  'tencent-qq': React.lazy(() => import('react-bootstrap-icons/dist/icons/tencent-qq')),
  'terminal-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/terminal-dash')),
  'terminal-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/terminal-fill')),
  'terminal-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/terminal-plus')),
  'terminal-split': React.lazy(() => import('react-bootstrap-icons/dist/icons/terminal-split')),
  'terminal-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/terminal-x')),
  terminal: React.lazy(() => import('react-bootstrap-icons/dist/icons/terminal')),
  'text-center': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-center')),
  'text-indent-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-indent-left')),
  'text-indent-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-indent-right')),
  'text-left': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-left')),
  'text-paragraph': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-paragraph')),
  'text-right': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-right')),
  'text-wrap': React.lazy(() => import('react-bootstrap-icons/dist/icons/text-wrap')),
  'textarea-resize': React.lazy(() => import('react-bootstrap-icons/dist/icons/textarea-resize')),
  'textarea-t': React.lazy(() => import('react-bootstrap-icons/dist/icons/textarea-t')),
  textarea: React.lazy(() => import('react-bootstrap-icons/dist/icons/textarea')),
  'thermometer-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/thermometer-half')),
  'thermometer-high': React.lazy(() => import('react-bootstrap-icons/dist/icons/thermometer-high')),
  'thermometer-low': React.lazy(() => import('react-bootstrap-icons/dist/icons/thermometer-low')),
  'thermometer-snow': React.lazy(() => import('react-bootstrap-icons/dist/icons/thermometer-snow')),
  'thermometer-sun': React.lazy(() => import('react-bootstrap-icons/dist/icons/thermometer-sun')),
  thermometer: React.lazy(() => import('react-bootstrap-icons/dist/icons/thermometer')),
  'three-dots-vertical': React.lazy(() => import('react-bootstrap-icons/dist/icons/three-dots-vertical')),
  'three-dots': React.lazy(() => import('react-bootstrap-icons/dist/icons/three-dots')),
  'thunderbolt-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/thunderbolt-fill')),
  thunderbolt: React.lazy(() => import('react-bootstrap-icons/dist/icons/thunderbolt')),
  'ticket-detailed-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/ticket-detailed-fill')),
  'ticket-detailed': React.lazy(() => import('react-bootstrap-icons/dist/icons/ticket-detailed')),
  'ticket-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/ticket-fill')),
  'ticket-perforated-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/ticket-perforated-fill')),
  'ticket-perforated': React.lazy(() => import('react-bootstrap-icons/dist/icons/ticket-perforated')),
  ticket: React.lazy(() => import('react-bootstrap-icons/dist/icons/ticket')),
  tiktok: React.lazy(() => import('react-bootstrap-icons/dist/icons/tiktok')),
  'toggle-off': React.lazy(() => import('react-bootstrap-icons/dist/icons/toggle-off')),
  'toggle-on': React.lazy(() => import('react-bootstrap-icons/dist/icons/toggle-on')),
  'toggle2-off': React.lazy(() => import('react-bootstrap-icons/dist/icons/toggle2-off')),
  'toggle2-on': React.lazy(() => import('react-bootstrap-icons/dist/icons/toggle2-on')),
  toggles: React.lazy(() => import('react-bootstrap-icons/dist/icons/toggles')),
  toggles2: React.lazy(() => import('react-bootstrap-icons/dist/icons/toggles2')),
  tools: React.lazy(() => import('react-bootstrap-icons/dist/icons/tools')),
  tornado: React.lazy(() => import('react-bootstrap-icons/dist/icons/tornado')),
  'train-freight-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/train-freight-front-fill')),
  'train-freight-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/train-freight-front')),
  'train-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/train-front-fill')),
  'train-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/train-front')),
  'train-lightrail-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/train-lightrail-front-fill')),
  'train-lightrail-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/train-lightrail-front')),
  translate: React.lazy(() => import('react-bootstrap-icons/dist/icons/translate')),
  'trash-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/trash-fill')),
  trash: React.lazy(() => import('react-bootstrap-icons/dist/icons/trash')),
  'trash2-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/trash2-fill')),
  trash2: React.lazy(() => import('react-bootstrap-icons/dist/icons/trash2')),
  'trash3-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/trash3-fill')),
  trash3: React.lazy(() => import('react-bootstrap-icons/dist/icons/trash3')),
  'tree-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/tree-fill')),
  tree: React.lazy(() => import('react-bootstrap-icons/dist/icons/tree')),
  trello: React.lazy(() => import('react-bootstrap-icons/dist/icons/trello')),
  'triangle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/triangle-fill')),
  'triangle-half': React.lazy(() => import('react-bootstrap-icons/dist/icons/triangle-half')),
  triangle: React.lazy(() => import('react-bootstrap-icons/dist/icons/triangle')),
  'trophy-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/trophy-fill')),
  trophy: React.lazy(() => import('react-bootstrap-icons/dist/icons/trophy')),
  'tropical-storm': React.lazy(() => import('react-bootstrap-icons/dist/icons/tropical-storm')),
  'truck-flatbed': React.lazy(() => import('react-bootstrap-icons/dist/icons/truck-flatbed')),
  'truck-front-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/truck-front-fill')),
  'truck-front': React.lazy(() => import('react-bootstrap-icons/dist/icons/truck-front')),
  truck: React.lazy(() => import('react-bootstrap-icons/dist/icons/truck')),
  tsunami: React.lazy(() => import('react-bootstrap-icons/dist/icons/tsunami')),
  'tv-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/tv-fill')),
  tv: React.lazy(() => import('react-bootstrap-icons/dist/icons/tv')),
  twitch: React.lazy(() => import('react-bootstrap-icons/dist/icons/twitch')),
  twitter: React.lazy(() => import('react-bootstrap-icons/dist/icons/twitter')),
  'type-bold': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-bold')),
  'type-h1': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-h1')),
  'type-h2': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-h2')),
  'type-h3': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-h3')),
  'type-italic': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-italic')),
  'type-strikethrough': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-strikethrough')),
  'type-underline': React.lazy(() => import('react-bootstrap-icons/dist/icons/type-underline')),
  type: React.lazy(() => import('react-bootstrap-icons/dist/icons/type')),
  ubuntu: React.lazy(() => import('react-bootstrap-icons/dist/icons/ubuntu')),
  'ui-checks-grid': React.lazy(() => import('react-bootstrap-icons/dist/icons/ui-checks-grid')),
  'ui-checks': React.lazy(() => import('react-bootstrap-icons/dist/icons/ui-checks')),
  'ui-radios-grid': React.lazy(() => import('react-bootstrap-icons/dist/icons/ui-radios-grid')),
  'ui-radios': React.lazy(() => import('react-bootstrap-icons/dist/icons/ui-radios')),
  'umbrella-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/umbrella-fill')),
  umbrella: React.lazy(() => import('react-bootstrap-icons/dist/icons/umbrella')),
  unindent: React.lazy(() => import('react-bootstrap-icons/dist/icons/unindent')),
  union: React.lazy(() => import('react-bootstrap-icons/dist/icons/union')),
  unity: React.lazy(() => import('react-bootstrap-icons/dist/icons/unity')),
  'universal-access-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/universal-access-circle')),
  'universal-access': React.lazy(() => import('react-bootstrap-icons/dist/icons/universal-access')),
  'unlock-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/unlock-fill')),
  unlock: React.lazy(() => import('react-bootstrap-icons/dist/icons/unlock')),
  'upc-scan': React.lazy(() => import('react-bootstrap-icons/dist/icons/upc-scan')),
  upc: React.lazy(() => import('react-bootstrap-icons/dist/icons/upc')),
  upload: React.lazy(() => import('react-bootstrap-icons/dist/icons/upload')),
  'usb-c-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-c-fill')),
  'usb-c': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-c')),
  'usb-drive-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-drive-fill')),
  'usb-drive': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-drive')),
  'usb-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-fill')),
  'usb-micro-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-micro-fill')),
  'usb-micro': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-micro')),
  'usb-mini-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-mini-fill')),
  'usb-mini': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-mini')),
  'usb-plug-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-plug-fill')),
  'usb-plug': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-plug')),
  'usb-symbol': React.lazy(() => import('react-bootstrap-icons/dist/icons/usb-symbol')),
  usb: React.lazy(() => import('react-bootstrap-icons/dist/icons/usb')),
  valentine: React.lazy(() => import('react-bootstrap-icons/dist/icons/valentine')),
  valentine2: React.lazy(() => import('react-bootstrap-icons/dist/icons/valentine2')),
  'vector-pen': React.lazy(() => import('react-bootstrap-icons/dist/icons/vector-pen')),
  'view-list': React.lazy(() => import('react-bootstrap-icons/dist/icons/view-list')),
  'view-stacked': React.lazy(() => import('react-bootstrap-icons/dist/icons/view-stacked')),
  vimeo: React.lazy(() => import('react-bootstrap-icons/dist/icons/vimeo')),
  'vinyl-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/vinyl-fill')),
  vinyl: React.lazy(() => import('react-bootstrap-icons/dist/icons/vinyl')),
  virus: React.lazy(() => import('react-bootstrap-icons/dist/icons/virus')),
  virus2: React.lazy(() => import('react-bootstrap-icons/dist/icons/virus2')),
  voicemail: React.lazy(() => import('react-bootstrap-icons/dist/icons/voicemail')),
  'volume-down-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-down-fill')),
  'volume-down': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-down')),
  'volume-mute-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-mute-fill')),
  'volume-mute': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-mute')),
  'volume-off-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-off-fill')),
  'volume-off': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-off')),
  'volume-up-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-up-fill')),
  'volume-up': React.lazy(() => import('react-bootstrap-icons/dist/icons/volume-up')),
  vr: React.lazy(() => import('react-bootstrap-icons/dist/icons/vr')),
  'wallet-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/wallet-fill')),
  wallet: React.lazy(() => import('react-bootstrap-icons/dist/icons/wallet')),
  wallet2: React.lazy(() => import('react-bootstrap-icons/dist/icons/wallet2')),
  watch: React.lazy(() => import('react-bootstrap-icons/dist/icons/watch')),
  water: React.lazy(() => import('react-bootstrap-icons/dist/icons/water')),
  'webcam-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/webcam-fill')),
  webcam: React.lazy(() => import('react-bootstrap-icons/dist/icons/webcam')),
  wechat: React.lazy(() => import('react-bootstrap-icons/dist/icons/wechat')),
  whatsapp: React.lazy(() => import('react-bootstrap-icons/dist/icons/whatsapp')),
  'wifi-1': React.lazy(() => import('react-bootstrap-icons/dist/icons/wifi-1')),
  'wifi-2': React.lazy(() => import('react-bootstrap-icons/dist/icons/wifi-2')),
  'wifi-off': React.lazy(() => import('react-bootstrap-icons/dist/icons/wifi-off')),
  wifi: React.lazy(() => import('react-bootstrap-icons/dist/icons/wifi')),
  wikipedia: React.lazy(() => import('react-bootstrap-icons/dist/icons/wikipedia')),
  wind: React.lazy(() => import('react-bootstrap-icons/dist/icons/wind')),
  'window-dash': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-dash')),
  'window-desktop': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-desktop')),
  'window-dock': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-dock')),
  'window-fullscreen': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-fullscreen')),
  'window-plus': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-plus')),
  'window-sidebar': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-sidebar')),
  'window-split': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-split')),
  'window-stack': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-stack')),
  'window-x': React.lazy(() => import('react-bootstrap-icons/dist/icons/window-x')),
  window: React.lazy(() => import('react-bootstrap-icons/dist/icons/window')),
  windows: React.lazy(() => import('react-bootstrap-icons/dist/icons/windows')),
  wordpress: React.lazy(() => import('react-bootstrap-icons/dist/icons/wordpress')),
  'wrench-adjustable-circle-fill': React.lazy(
    () => import('react-bootstrap-icons/dist/icons/wrench-adjustable-circle-fill')
  ),
  'wrench-adjustable-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/wrench-adjustable-circle')),
  'wrench-adjustable': React.lazy(() => import('react-bootstrap-icons/dist/icons/wrench-adjustable')),
  wrench: React.lazy(() => import('react-bootstrap-icons/dist/icons/wrench')),
  'x-circle-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-circle-fill')),
  'x-circle': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-circle')),
  'x-diamond-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-diamond-fill')),
  'x-diamond': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-diamond')),
  'x-lg': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-lg')),
  'x-octagon-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-octagon-fill')),
  'x-octagon': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-octagon')),
  'x-square-fill': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-square-fill')),
  'x-square': React.lazy(() => import('react-bootstrap-icons/dist/icons/x-square')),
  x: React.lazy(() => import('react-bootstrap-icons/dist/icons/x')),
  xbox: React.lazy(() => import('react-bootstrap-icons/dist/icons/xbox')),
  yelp: React.lazy(() => import('react-bootstrap-icons/dist/icons/yelp')),
  'yin-yang': React.lazy(() => import('react-bootstrap-icons/dist/icons/yin-yang')),
  youtube: React.lazy(() => import('react-bootstrap-icons/dist/icons/youtube')),
  'zoom-in': React.lazy(() => import('react-bootstrap-icons/dist/icons/zoom-in')),
  'zoom-out': React.lazy(() => import('react-bootstrap-icons/dist/icons/zoom-out'))
} as const
